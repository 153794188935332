import React from "react";
import loader from "../../assets/loader.gif";

const MainLoader = () => {
  return (
    <div
      style={{ backgroundColor: "white", height: "60vh", width: "100%" }}
      className="flex items-center justify-center flex-col"
    >
      <img src={loader} alt="loader" width="180px" />
    </div>
  );
};

export default MainLoader;
