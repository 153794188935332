import React from "react";
import loader from "../assets/loader.gif";

const LoadingScreen = () => {
  return (
    <div
      style={{ backgroundColor: "white", height: "95vh", width: "100%" }}
      className="flex items-center justify-center flex-col"
    >
      <img src={loader} alt="loader" width="180px" />
    </div>
  );
};

export default LoadingScreen;
