import React, {useState, useContext, useEffect} from 'react'
import AuthContext from "../../../contexts/AuthContext";
import { Input, Button, Modal, List, Card, Flex, Result, DatePicker, Alert } from "antd";
import moment from "moment-timezone";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { GoogleMap, Marker, useLoadScript, DirectionsRenderer, Autocomplete } from "@react-google-maps/api";
import Geocode from "react-geocode";
import createRoot from 'react-dom';
import location from "../../../assets/location.png";
import LoadingScreen from "../../../utils/LoadingScreen";
import { MdAdminPanelSettings, MdPermContactCalendar } from "react-icons/md";
import { CheckCircleTwoTone } from "@ant-design/icons";

const CreateAppTask = ({ open, onHide, onTaskAssigned }) => {
    const { role } = useContext(AuthContext);
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [admin, setAdmin] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isTaskAssigned, setIsTaskAssigned] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [task, setTask] = useState('');
    const [description, setDescription] = useState('');
    const [lanes, setLanes] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const { TextArea } = Input;
    useEffect(() => {
        const fetchWebUsers = async () => {
            if (!open || role !== 'Admin') return;
            setLoading(true);
            try {
                const token = JSON.parse(window.localStorage.getItem("token"));
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}task/list_mobileusers`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.data.users) {
                    throw new Error('Failed to fetch app users');
                }
                const formattedUsers = response.data.users.map(user => ({
                    label: `${user.name} (${user.unique_verification_code})`,
                    value: user.name,
                    unique_verification_code: user.unique_verification_code
                }));
                setUsers(formattedUsers);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWebUsers();
    }, [open, role]);
    useEffect(() => {
        const fetchAdminName = async () => {
            if (!open || role !== 'Admin') return;
            setLoading(true);
            try {
                const token = JSON.parse(window.localStorage.getItem("token"));
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}task/get_user_info`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.data.result) {
                    throw new Error('Failed to fetch admin name');
                }
                const fetchedAdmin = response.data.result;
                setAdmin([fetchedAdmin]);
                setSelectedAdmin(fetchedAdmin);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchAdminName();
    }, [open, role]);

    const assignTask = async () => {
        if (role !== 'Admin') {
            return;
        }
        setLoading(true);
        try {
            if (!selectedUser || !selectedUser.value || !selectedUser.unique_verification_code) {
                setError('Please select a user to assign the task.');
                return;
            }
            if (!startingPoint) {
                setError('Please select a starting point for the task.');
                return;
            }
            if (!endingPoint) {
                setError('Please select an ending point for the task.');
                return;
            }
            if (!lanes) {
                setError('Please fill the number of lanes for the task.');
                return;
            }
            if (!task) {
                setError('Please fill Task Title');
                return;
            }
            if (!description) {
                setError('Please fill Task Description')
                return;
            }
            if (!dueDate) {
                setError('Please fill Due Date')
                return;
            }
            const uid = uuidv4();
            const assignedByName = selectedAdmin ? selectedAdmin.name : '';
            const payload = {
                taskid: uid,
                assignedTo: selectedUser.value,
                assignedUserCode: selectedUser.unique_verification_code,
                startingPoint: startingPoint.address,
                endingPoint: endingPoint.address,
                lanes: lanes,
                taskTitle: task,
                taskDescription: description,
                dueDate: dueDate,
                assignedBy: assignedByName,
            };
            const token = JSON.parse(window.localStorage.getItem("token"));
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}task/apptask_assign`, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Task assigned successfully:', response.data);
            onTaskAssigned();
            setIsTaskAssigned(true);
            setTimeout(() => {
                setIsTaskAssigned(false);
                onHide();
                resetForm();
            }, 5000);
            setSelectedUser({});
            setSelectedAdmin('');
            setLanes('');
            setTask('');
            setDescription('');
            setDueDate(null);
            setError(null);
        } catch (error) {
            setError(error.message);
            console.error('Error assigning task:', error);
        } finally {
            setLoading(false);
        }
    };
    const resetForm = () => {
        setSelectedUser({});
        setUsers([]);
        setSelectedAdmin('');
        setAdmin([]);
        setLanes('');
        setTask('');
        setDescription('');
        setDueDate(null);
        setError(null);
        setLoading(false);
    };

    const filteredUsers = users.filter(user =>
        user.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEYS);
    const mapId = "c7ce1a914d921fb";
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEYS,
        libraries: ["places"],
    });
    const [startingAddress, setStartingAddress] = useState('');
    const [endingAddress, setEndingAddress] = useState('');
    const [currentLocation, setCurrentLocation] = useState({
        lat: 28.6566389,
        lng: 77.2180907,
    });
    const [mapCenter, setMapCenter] = useState(currentLocation);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [startingPoint, setStartingPoint] = useState(null);
    const [endingPoint, setEndingPoint] = useState(null);
    const [lastFocusedInput, setLastFocusedInput] = useState(null);
    const onMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        Geocode.fromLatLng(lat.toString(), lng.toString()).then(
            (response) => {
                const address = response.results[0].formatted_address;
                if (lastFocusedInput === 'starting') {
                    setStartingAddress(address);
                    setStartingPoint({ address, location: { lat, lng } });
                    setMapCenter({ lat, lng });
                } else if (lastFocusedInput === 'ending') {
                    setEndingAddress(address);
                    setEndingPoint({ address, location: { lat, lng } });
                    setMapCenter({ lat, lng });
                }
            },
            (error) => {
                console.error(error);
            }
        );
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            }, (error) => {
                console.error("Error getting the geolocation: ", error);
            });
        }
    }, []);
    const addLocationControl = (map) => {
        const locationControlDiv = document.createElement('div');
        createRoot.render(<LocationButton map={map} />, locationControlDiv);
        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationControlDiv);
    };
    const LocationButton = ({ map }) => {
        const goToCurrentLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const currentLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    map.setCenter(currentLocation);
                }, (error) => {
                    console.error("Error fetching geolocation: ", error);
                });
            }
        };

        return (
            <img src={location} onClick={goToCurrentLocation} alt="location" className="location" style={{ cursor: 'pointer', position: 'relative', right: '20px' }} />

        );
    };
    useEffect(() => {
        const calculateRoute = async () => {
            if (startingPoint && endingPoint) {
                const directionsService = new window.google.maps.DirectionsService();
                const results = await directionsService.route({
                    origin: startingPoint.location,
                    destination: endingPoint.location,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                });
                setDirectionsResponse(results);
            }
        };
        calculateRoute();
    }, [startingPoint, endingPoint]);
    const handleOnSubmit = async (values) => {
        if (window.google && values.startingPoint && values.endingPoint) {
            const directionsService = new window.google.maps.DirectionsService();
            const results = await directionsService.route({
                origin: values.startingPoint,
                destination: values.endingPoint,
                travelMode: window.google.maps.TravelMode.DRIVING,
            });
            setDirectionsResponse(results);
        } else {
            setDirectionsResponse(null);
        }
    };
    const [startingAutocomplete, setStartingAutocomplete] = useState(null);
    const [endingAutocomplete, setEndingAutocomplete] = useState(null);
    const onAutocompletePlaceChanged = (type) => {
        const autocomplete = type === 'starting' ? startingAutocomplete : endingAutocomplete;
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.error("No location found for input: ", type);
                return;
            }
            const location = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            if (type === 'starting') {
                if (place.formatted_address.trim() === '') {
                    setStartingPoint(null);
                } else {
                    setStartingPoint({ address: place.formatted_address, location });
                }
            } else {
                if (place.formatted_address.trim() === '') {
                    setEndingPoint(null);
                } else {
                    setEndingPoint({ address: place.formatted_address, location });
                }
            }
        } else {
            console.error("Autocomplete not loaded for input: ", type);
        }
        if (!startingPoint && !endingPoint) {
            setDirectionsResponse(null);
        }
    };
    const disabledDate = current => {
        return current && current < moment().startOf('day');
    };


    const renderContent = () => {
        return (
            <>
                <>
                    {isTaskAssigned ? (
                        <Result
                            status="success"
                            title="Successfully Assigned Task!"
                            subTitle="The task has been successfully assigned. This message will close automatically."
                        />
                    ) : (
                        <>
                            <Card
                                title={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Assign User Survey Task</span>
                                        <div className="flex items-center" style={{ position: 'relative', width: 250, height: 45 }}>
                                            <MdAdminPanelSettings style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 10 }} />
                                            <Input
                                                disabled={true}
                                                value={selectedAdmin ? `Assigne by ${selectedAdmin.name}` : ''}
                                                style={{ paddingLeft: 35, width: '100%', height: '100%', borderRadius: 20 }}
                                            />
                                        </div>
                                    </div>
                                }
                                bordered={false}
                                style={{ width: '100%' }}
                            >
                                <Flex gap="small">
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: 450,
                                            width: 320,
                                            backgroundColor: '#ededed',
                                            overflow: 'hidden',
                                            padding: '16px',
                                            border: '1px solid rgba(140, 140, 140, 0.35)',
                                        }}
                                    >
                                        <div style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Select a user for this task</div>
                                        <input
                                            type="text"
                                            placeholder="   Search for a users"
                                            style={{ width: '100%', height: 30, borderRadius: 5, marginBottom: '8px' }}
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <div
                                            style={{
                                                overflowY: 'auto',
                                                maxHeight: '240px',
                                            }}
                                        >
                                            {selectedUser.value ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        padding: '10px',
                                                        backgroundColor: '#f0f0f0',
                                                    }}
                                                >
                                                    <MdPermContactCalendar />{selectedUser.label}
                                                </div>
                                            ) : (
                                                <List size="small">
                                                    {filteredUsers.map((user, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '4px',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            onClick={() => setSelectedUser(user)}
                                                        >
                                                            <MdPermContactCalendar />{user.label}
                                                        </div>
                                                    ))}
                                                </List>
                                            )}
                                        </div>
                                        {selectedUser.value && (
                                            <button
                                                style={{
                                                    marginTop: '10px',
                                                    color: '#007bff',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => setSelectedUser({})}
                                            >
                                                Change User
                                            </button>
                                        )}
                                    </div>
                                    <div
                                        id="taskdiv"
                                        style={{
                                            height: 450,
                                            flexGrow: 1,
                                            overflow: 'hidden',
                                            padding: '0px 16px',

                                        }}
                                    >
                                        <Flex gap="small" vertical>
                                            <div className="p-field">
                                                <label htmlFor="startingPoint" style={{ display: 'block', fontSize: '16px', fontWeight: '600' }}>Starting Point</label>
                                                <Autocomplete
                                                    onLoad={(autocomplete) => setStartingAutocomplete(autocomplete)}
                                                    onPlaceChanged={() => onAutocompletePlaceChanged('starting')}
                                                >

                                                    <input
                                                        type="text"
                                                        style={{ width: '100%', padding: '0.2rem', borderColor: '#007bff', borderWidth: '1px', borderStyle: 'solid' }}
                                                    />
                                                </Autocomplete>
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="endingPoint" style={{ display: 'block', fontSize: '16px', fontWeight: '600' }}>Ending Point</label>
                                                <Autocomplete
                                                    onLoad={(autocomplete) => setEndingAutocomplete(autocomplete)}
                                                    onPlaceChanged={() => onAutocompletePlaceChanged('ending')}
                                                >
                                                    <input
                                                        type="text"
                                                        style={{ width: '100%', padding: '0.2rem', borderColor: '#007bff', borderWidth: '1px', borderStyle: 'solid' }}
                                                    />
                                                </Autocomplete>
                                            </div>

                                            <div className="p-field">
                                                <label htmlFor="lanes" style={{ display: 'block', fontSize: '16px', fontWeight: '600' }}>Number of Lanes</label>
                                                <TextArea
                                                    id="lanes"
                                                    value={lanes}
                                                    onChange={(e) => setLanes(e.target.value)}
                                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                                    style={{ width: '100%', padding: '0.2rem', borderColor: '#007bff', borderWidth: '1px', borderStyle: 'solid' }}
                                                />
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="task" style={{ display: 'block', fontSize: '16px', fontWeight: '600' }}>Task Title</label>
                                                <TextArea
                                                    id="task"
                                                    value={task}
                                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                                    onChange={(e) => setTask(e.target.value)}
                                                    style={{ width: '100%', padding: '0.2rem', borderColor: '#007bff', borderWidth: '1px', borderStyle: 'solid' }}
                                                />
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="description" style={{ display: 'block', fontSize: '16px', fontWeight: '600' }}>Task Description</label>
                                                <TextArea
                                                    id="description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                                    style={{ width: '100%', padding: '0.2rem', borderColor: '#007bff', borderWidth: '1px', borderStyle: 'solid' }}
                                                />
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="date" style={{ display: 'block', fontSize: '16px', fontWeight: '600' }}>Due Date</label>
                                                <DatePicker
                                                    id="date"
                                                    value={dueDate}
                                                    onChange={(date, dateString) => setDueDate(date)}
                                                    style={{ width: '100%', padding: '0.2rem', borderColor: '#007bff', borderWidth: '1px', borderStyle: 'solid' }}
                                                    disabledDate={disabledDate}
                                                />
                                            </div>
                                        </Flex>
                                    </div>
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: 450,
                                            flexGrow: 1,
                                            backgroundColor: '#ededed',
                                            overflow: 'hidden',
                                            border: '1px solid rgba(140, 140, 140, 0.35)',
                                        }}
                                    >
                                        {!isLoaded ? (
                                            <div className="ml-24">
                                                <LoadingScreen />
                                            </div>
                                        ) : (
                                            <GoogleMap
                                                key={`${startingAddress}-${endingAddress}`}
                                                onClick={onMapClick}
                                                mapId={mapId}
                                                mapContainerStyle={{ width: "100%", height: "100%" }}
                                                zoom={15}
                                                center={mapCenter}
                                                //center={startingPoint ? { lat: startingPoint.location.lat, lng: startingPoint.location.lng } : currentLocation}
                                                onLoad={(map) => {
                                                    addLocationControl(map);
                                                }}
                                                options={{
                                                    disableDefaultUI: true,
                                                    zoomControl: true,
                                                    fullscreenControl: false,
                                                    streetViewControl: false,
                                                    mapTypeControl: false,
                                                    scaleControl: true,
                                                    rotateControl: true,
                                                    clickableIcons: true,
                                                }}
                                            >
                                                {startingPoint && (
                                                    <Marker position={{ lat: startingPoint.location.lat, lng: startingPoint.location.lng }} />
                                                )}
                                                {endingPoint && (
                                                    <Marker position={{ lat: endingPoint.location.lat, lng: endingPoint.location.lng }} />
                                                )}

                                                {directionsResponse && (
                                                    <DirectionsRenderer directions={directionsResponse} />
                                                )}
                                            </GoogleMap>
                                        )}

                                    </div>
                                </Flex>
                                {error && (<Alert message={error} type="error" showIcon closable onClose={() => setError(null)} style={{ marginBottom: 1 }} />)}
                            </Card>
                        </>
                    )}
                </>
            </>
        );
    };
    const footerContent = () => {
        return [
            <div key="button-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button key="cancel" danger onClick={() => { onHide(); resetForm(); }} ghost>Cancel</Button>
                <Button key="assign" type="primary" onClick={assignTask} icon={<CheckCircleTwoTone />} style={{ background: '#1967D2' }}>Assign</Button>
            </div>
        ];
    }
    return (
        <Modal
            centered
            closable={false}
            open={open}
            onCancel={onHide}
            footer={footerContent()}
            width="90vw"
            modal
        >
            {renderContent()}
        </Modal>
    );
};

export default CreateAppTask