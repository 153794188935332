import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';


const AdminGuard = ({ children }) => {
    const { isAuthenticated, role } = useContext(AuthContext);
    console.log('AdminGuard Check', { isAuthenticated, role });
  
    if (!isAuthenticated || role !== "Admin") {
      return <Navigate to="/app" replace />;
    }
  
    return children;
  };
  

export default AdminGuard;
