import React from 'react'
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Button, Popover } from 'antd';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GavelIcon from '@mui/icons-material/Gavel';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from '@mui/icons-material/Menu';
const ProfileMenu = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = React.useState(false);
    const handleLogout = () => {
        logout();
        navigate("/login");
    };
    const handleProfile = () => {
        navigate("/profile");
    };
    return (
        <Popover
            placement="bottomRight"
            content={
                <>
                    <ul className="flex" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <li className="cursor-pointer">
                            <Button className="flex items-start cursor-pointer" style={{ width: '100%' }} onClick={handleProfile}>
                                <PersonIcon className="text-[#425466] mr-2" />
                                Profile
                            </Button>
                        </li>
                        <li className="cursor-pointer">
                            <Button className="cursor-pointer flex items-start" style={{ width: '100%' }}>
                                <GavelIcon className="text-[#425466] mr-2" />
                                T&C
                            </Button>
                        </li>
                        <li className="cursor-pointer">
                            <Button className="cursor-pointer flex items-start" style={{ width: '100%' }}>
                                <ContactSupportIcon className="text-[#425466] mr-2" />
                                Support
                            </Button>
                        </li>
                        <li className="cursor-pointer">
                            <Button className="cursor-pointer flex items-start" style={{ width: '100%' }} onClick={handleLogout}>
                                <LogoutIcon className="text-[#425466] mr-2" />
                                Logout
                            </Button>
                        </li>
                    </ul>
                </>
            }
            trigger="click"
            open={openMenu}
            onOpenChange={setOpenMenu}
        >
            <button className="border border-gray-400 p-2 rounded-full flex items-center">
                <MenuIcon className="text-[#425466] cursor-pointer" />
                <AccountCircleIcon className="text-[#425466] cursor-pointer ml-2" />
            </button>
        </Popover>
    )
}

export default ProfileMenu