import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Segmented } from 'antd';
import LoadingScreen from "../../../utils/LoadingScreen";
import { Link } from "react-router-dom";
import { IoMapSharp } from "react-icons/io5";
import { PiVideoDuotone } from "react-icons/pi";
import { RiCalculatorFill, RiArticleFill, RiFileDownloadFill, RiHistoryFill, RiNotification3Fill, RiTrafficLightLine } from "react-icons/ri";
import logo from "../../../assets/RVlogo.svg";
//import logo from "../../assets/2.png";
import "./Notifications.css";



const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function containsMultipleIds(listOfIds) {
    try {
      const ids = JSON.parse(listOfIds);
      return Array.isArray(ids) && ids.length > 1;
    } catch (error) {
      return false;
    }
  }
  const listOfIds = searchParams.get("listOfIds");
  const menus = [

    {
      name: "Map",
      link:
        `${window.location.origin.toString()}/pavement/multiMap?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: IoMapSharp,
    },
    {
      name: "Report",
      link: containsMultipleIds(listOfIds) 
        ? "#" 
        : `${window.location.origin.toString()}/pavement/report?listOfIds=${listOfIds}`,
      icon: RiArticleFill,
      onClick: containsMultipleIds(listOfIds) 
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        } 
        : undefined,
    },
    {
      name: "Video Inspection",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/video?listOfIds=${listOfIds}`,
      icon: PiVideoDuotone,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Notifications",
      link:
        `${window.location.origin.toString()}/pavement/notifications?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiNotification3Fill,
    },
    {
      name: "VersionControl",
      link: "#",
      icon: RiHistoryFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "Budget Calculator",
      link:
        `${window.location.origin.toString()}/pavement/calculator?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiCalculatorFill,
    },
    {
      name: "Download Report",
      link: "#",
      icon: RiFileDownloadFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },

    },
    {
      name: "Traffic Management System",
      link:
        `${window.location.origin.toString()}/pavement/traffic?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiTrafficLightLine,
    },
  ];
  return (
    <div className="w-full">
      <div
        className="bg-[#F5FAFF] h-full w-18
           text-[#425466] px-4 z-100 fixed"
      >
        <div className="mt-4 flex flex-col gap-4 relative">
          <Link to="/pavement/app" className="mb-2">
            <img src={logo} alt="Logo" className="w-10 h-10 cursor-pointer" />
          </Link>
          {menus?.map((menu, i) => {
            return (
              <Link
                to={menu.link}
                key={i}
                className={` ${menu?.margin && "mt-5"
                  } group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
                title={menu.name}
                onClick={menu.onClick}
              >
                <div>{React.createElement(menu?.icon, { size: "21" })}</div>
              </Link>
            );
          })}
        </div>
      </div>

      {isLoading ? (
        <div className="">
          <LoadingScreen />
        </div>
      ) : (
        <div className="ml-[70px] w-[94%]">
          <div className="main">
            <div className="notification-main">


            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
