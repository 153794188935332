import React from "react";
import { Box, LinearProgress } from "@mui/material";

const SplashScreen = () => {
  return (
    <div className="flex items-center justify-center fixed top-0 left-0 w-full h-full bg-white z-50">
      <div className="w-full max-w-xs p-10">
        <Box width={400}>
          <LinearProgress />
        </Box>
      </div>
    </div>
  );
};

export default SplashScreen;
