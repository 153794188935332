import React, { createContext, useEffect, useReducer,useState } from "react";
import { useNavigate } from "react-router-dom";
import SplashScreen from "../utils/splashScreen/splashScreen";
import axios from "axios";
import qs from "qs";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isTimedOut: false,
  user: null,
  role: null,
  permissions: null,
};

const setHeader = () => {
  const token = JSON.parse(window.localStorage.getItem("token"));
  console.log("token from setHeader", token);
  axios.defaults.headers.common.token = token;
  axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      console.log("Case initialize from auth context");
      const { isAuthenticated, user, role, permissions } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        role,
        permissions,
      };
    }

    case "LOGIN": {
      console.log("Case LOGIN from auth context");
      const { user, role, permissions } = action.payload;
      window.localStorage.setItem("role", role);
      window.localStorage.setItem("permissions", JSON.stringify(permissions));
      return {
        ...state,
        isAuthenticated: true,
        user,
        role,
        permissions,
      };
    }
    case "LOGOUT": {
      console.log("Case logout from context");
      return {
        ...state,
        isAuthenticated: false,
        isTimedOut: action.isTimedOut,
        user: null,
        role: null,
        permissions: null,
      };
    }
    case "REGISTER": {
      console.log("Case register from auth context;");
      const { user, role, permissions } = action.payload;
      window.localStorage.setItem("role", role);
      window.localStorage.setItem("permissions", JSON.stringify(permissions));
      return {
        ...state,
        isAuthenticated: true,
        user,
        role,
        permissions,
      };
    }

    default: {
      console.log("Case default");
      return { ...state };
    }
  }
};
const AuthContext = createContext({
  ...initialAuthState,
  //method: 'BAuth', TODO: I don't understand the meaning of this method in the context
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  activateProfile: () => Promise.resolve(),
  requestOTP: () => Promise.resolve(),
  validateOTP: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  requestDemo: () => Promise.resolve(),
  validateDemo: () => Promise.resolve(),
  useractivateProfile: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const login = async (email, password) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/login`,
        {
          email,
          password,
        }
      );
      const user = response.data;
      if (user) {
        window.localStorage.setItem("token", JSON.stringify(user?.result.token));
        window.localStorage.setItem("role", user?.result.user_details.role, JSON.stringify(user?.result.user_details));
        window.localStorage.setItem("permissions", JSON.stringify(user?.result.user_details.permissions));
        dispatch({
          type: "LOGIN",
          payload: {
            user: user.result.user_details,
            role: user.result.user_details.role,
            permissions: user.result.user_details.permissions,
            //userInfo,
          },
        });
        navigate("/home");
      } else {
        return response;
      }
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  const logout = async (isTimedOut = false) => {
    try {
      const token = JSON.parse(window.localStorage.getItem("token"));
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      axios.defaults.headers.common.Authorization = null;
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("role");
      window.localStorage.removeItem("permissions");
      dispatch({ type: "LOGOUT", isTimedOut });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };
  const register = async (email, password, name, unique_verification_code, role) => {
    try {
      const data = qs.stringify({
        email,
        password,
        name,
        unique_verification_code,
        role,
      });
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/signup`,
        data
      );

      const user = response.data;
      if (user) {
        window.localStorage.setItem("user", JSON.stringify(user));
        window.localStorage.setItem("role", role);
        dispatch({
          type: "REGISTER",
          payload: {
            user,
            role,
          },
        });
      } else {
        return response.data.responseMessage;
      }
    } catch (err) {
      return err;
    }
  };

  const requestOTP = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/requestOTP`,
        {
          email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      return err.response;
    }
  }


  const validateOTP = async (email, otp) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/validateOTP`,
        {
          email,
          otp,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      return err.response;
    }
  };

  const resetPassword = async (password, confirmPassword, token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/resetPassword`,
        {
          password,
          confirmPassword,
          token,
        },
        {
          headers: {
            // Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      return err.response;
    }
  };

  const requestDemo = async (email, name) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/requestDemo`,
        {
          email: email,
          name: name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      return err.response;
    }
  }

  const validateDemo = async (email, name, otp) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/validateDemo`,
        {
          email,
          name,
          otp,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (err) {
      return err.response;
    }
  }

  const activateProfile = async (email, password, name, organization, city) => {
    try {
      const data = qs.stringify({
        email,
        password,
        name,
        organization,
        city,
      });
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/activateProfile`,
        data
      );

      const user = response.data;
      if (user) {
        window.localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: "REGISTER",
          payload: {
            user,
          },
        });
      } else {
        return response.data.responseMessage;
      }
    } catch (err) {
      return err;
    }
  };

  const useractivateProfile = async (email, password, name, organization, organization_id, city) => {
    try {
      const data = qs.stringify({
        email,
        password,
        name,
        organization,
        organization_id,
        city,
      });
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webAuth/activateUserProfile`,
        data
      );

      const user = response.data;
      if (user) {
        window.localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: "REGISTER",
          payload: {
            user,
          },
        });
      } else {
        return response.data.responseMessage;
      }
    } catch (err) {
      return err;
    }
  }

  // useEffect(() => {
  //   setHeader();
  //   const initialise = async () => {
  //     try {
  //       const token = JSON.parse(window.localStorage.getItem("token"));
  //       const role = window.localStorage.getItem("role");
  //       const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  //       dispatch({
  //         type: "INITIALISE",
  //         payload: {
  //           isAuthenticated: true,
  //           user: null,
  //           role,
  //           permissions,
  //         },
  //       });
  //       if (!token) {
  //         dispatch({
  //           type: "INITIALISE",
  //           payload: {
  //             isAuthenticated: false,
  //             user: null,
  //             role: null,
  //             permissions: [],
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       console.error("Error initialising auth context:", err);
  //       dispatch({
  //         type: "INITIALISE",
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //           role: null,
  //           permissions: [],
  //         },
  //       });
  //     }
  //   };
  //   initialise();
  // }, [state.user, state.role, state.permissions]);

  useEffect(() => {
    setHeader();
    const initialise = async () => {
      try {
        const token = JSON.parse(window.localStorage.getItem("token"));
        const role = window.localStorage.getItem("role");
        const permissions = JSON.parse(window.localStorage.getItem("permissions"));
        console.log("permissions from auth context", permissions);
        console.log("role from auth context", role);
        if (token) {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user: null,
              role,
              permissions,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
              role: null,
              permissions: null,
            },
          });
        }
      } catch (err) {
        console.error("Error initialising auth context:", err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
            role: null,
            permissions: null,
          },
        });
      }
    };
    initialise();
  }, [state.user, state.role]);


  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider value={{ ...state, login, logout, register, activateProfile, requestOTP, validateOTP, resetPassword, requestDemo, validateDemo, useractivateProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;






















// import React, { createContext, useEffect, useReducer } from "react";
// import SplashScreen from "../components/splashScreen/splashScreen";
// import axios from "axios";
// import qs from "qs";

// const initialAuthState = {
//   isAuthenticated: false,
//   isInitialised: false,
//   isTimedOut: false,
//   user: null,
//   role: null,
  
// };

// const setHeader = () => {
//   const token = JSON.parse(window.localStorage.getItem("token"));
//   console.log("token from setHeader", token);
//   axios.defaults.headers.common.token = token;
//   axios.defaults.headers.post["Content-Type"] =
//     "application/x-www-form-urlencoded";
// };

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "INITIALISE": {
//       console.log("Case initialize from auth context");
//       const { isAuthenticated, user, role } = action.payload;

//       return {
//         ...state,
//         isAuthenticated,
//         isInitialised: true,
//         user,
//         role,
//       };
//     }

//     case "LOGIN": {
//       console.log("Case LOGIN from auth context");
//       const { user, role } = action.payload;
//       window.localStorage.setItem("role", role);
//       return {
//         ...state,
//         isAuthenticated: true,
//         user,
//         role,
//       };
//     }
//     case "LOGOUT": {
//       console.log("Case logout from context");
//       return {
//         ...state,
//         isAuthenticated: false,
//         isTimedOut: action.isTimedOut,
//         user: null,
//         role: null,
//       };
//     }
//     case "REGISTER": {
//       console.log("Case register from auth context;");
//       const { user, role } = action.payload;
//       window.localStorage.setItem("role", role);
//       return {
//         ...state,
//         isAuthenticated: true,
//         user,
//         role,
//       };
//     }

//     default: {
//       console.log("Case default");
//       return { ...state };
//     }
//   }
// };
// const AuthContext = createContext({
//   ...initialAuthState,
//   //method: 'BAuth', TODO: I don't understand the meaning of this method in the context
//   login: () => Promise.resolve(),
//   logout: () => Promise.resolve(),
//   register: () => Promise.resolve(),
//   activateProfile: () => Promise.resolve(),
//   requestOTP: () => Promise.resolve(),
//   validateOTP: () => Promise.resolve(),
//   resetPassword: () => Promise.resolve(),
//   requestDemo: () => Promise.resolve(),
//   validateDemo: () => Promise.resolve(),
//   useractivateProfile: () => Promise.resolve(),
// });

// export const AuthProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(reducer, initialAuthState);
//   const login = async (email, password, role) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/login`,
//         {
//           email,
//           password,
//           role,
//         }
//       );
//       const user = response.data;
//       if (user) {
//         window.localStorage.setItem("token", JSON.stringify(user?.result.token));
//         window.localStorage.setItem("role", user?.result.user_details.role, JSON.stringify(user?.result.user_details));
//         dispatch({
//           type: "LOGIN",
//           payload: {
//             user: user.result.user_details,
//             role,
//             //userInfo,
//           },
//         });
//       } else {
//         return response;
//       }
//     } catch (err) {
//       return err;
//     }
//   };

//   const logout = async (isTimedOut = false) => {
//     try {
//       const token = JSON.parse(window.localStorage.getItem("token"));
//       await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/logout`,
//         null,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       axios.defaults.headers.common.Authorization = null;
//       window.localStorage.removeItem("token");
//       window.localStorage.removeItem("role");
//       dispatch({ type: "LOGOUT", isTimedOut });
//     } catch (error) {
//       console.error("Logout failed:", error);
//     }
//   };
//   const register = async (email, password, name, unique_verification_code, role) => {
//     try {
//       const data = qs.stringify({
//         email,
//         password,
//         name,
//         unique_verification_code,
//         role,
//       });
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/signup`,
//         data
//       );

//       const user = response.data;
//       if (user) {
//         window.localStorage.setItem("user", JSON.stringify(user));
//         window.localStorage.setItem("role", role);
//         dispatch({
//           type: "REGISTER",
//           payload: {
//             user,
//             role,
//           },
//         });
//       } else {
//         return response.data.responseMessage;
//       }
//     } catch (err) {
//       return err;
//     }
//   };

//   const requestOTP = async (email) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/requestOTP`,
//         {
//           email: email,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       return response;
//     } catch (err) {
//       return err.response;
//     }
//   }


//   const validateOTP = async (email, otp) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/validateOTP`,
//         {
//           email,
//           otp,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       return response;
//     } catch (err) {
//       return err.response;
//     }
//   };

//   const resetPassword = async (password, confirmPassword, token) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/resetPassword`,
//         {
//           password,
//           confirmPassword,
//           token,
//         },
//         {
//           headers: {
//             // Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       return response;
//     } catch (err) {
//       return err.response;
//     }
//   };

//   const requestDemo = async (email, name) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/requestDemo`,
//         {
//           email: email,
//           name: name,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       return response;
//     } catch (err) {
//       return err.response;
//     }
//   }

//   const validateDemo = async (email, name, otp) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/validateDemo`,
//         {
//           email,
//           name,
//           otp,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       return response;
//     } catch (err) {
//       return err.response;
//     }
//   }

//   const activateProfile = async (email, password, name, organization, city) => {
//     try {
//       const data = qs.stringify({
//         email,
//         password,
//         name,
//         organization,
//         city,
//       });
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/activateProfile`,
//         data
//       );

//       const user = response.data;
//       if (user) {
//         window.localStorage.setItem("user", JSON.stringify(user));
//         dispatch({
//           type: "REGISTER",
//           payload: {
//             user,
//           },
//         });
//       } else {
//         return response.data.responseMessage;
//       }
//     } catch (err) {
//       return err;
//     }
//   };

//   const useractivateProfile = async (email, password, name, organization, organization_id, city) => {
//     try {
//       const data = qs.stringify({
//         email,
//         password,
//         name,
//         organization,
//         organization_id,
//         city,
//       });
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}webAuth/activateUserProfile`,
//         data
//       );

//       const user = response.data;
//       if (user) {
//         window.localStorage.setItem("user", JSON.stringify(user));
//         dispatch({
//           type: "REGISTER",
//           payload: {
//             user,
//           },
//         });
//       } else {
//         return response.data.responseMessage;
//       }
//     } catch (err) {
//       return err;
//     }
//   }

//   // useEffect(() => {
//   //   setHeader();
//   //   const initialise = async () => {
//   //     try {
//   //       const token = JSON.parse(window.localStorage.getItem("token"));
//   //       //const role = JSON.parse(window.localStorage.getItem("role"));
//   //       const role = window.localStorage.getItem("role");
//   //       dispatch({
//   //         type: "INITIALISE",
//   //         payload: {
//   //           isAuthenticated: true,
//   //           user: null,
//   //           role,
//   //         },
//   //       });
//   //       if (!token) {
//   //         dispatch({
//   //           type: "INITIALISE",
//   //           payload: {
//   //             isAuthenticated: false,
//   //             user: null,
//   //             role: null,
//   //           },
//   //         });
//   //       }
//   //     } catch (err) {
//   //       console.error("Error initialising auth context:", err);
//   //       dispatch({
//   //         type: "INITIALISE",
//   //         payload: {
//   //           isAuthenticated: false,
//   //           user: null,
//   //           //role: null,
//   //         },
//   //       });
//   //     }
//   //   };
//   //   initialise();
//   // }, [state.user, state.role]);

//   useEffect(() => {
//     setHeader();
//     const initialise = async () => {
//       try {
//         const token = JSON.parse(window.localStorage.getItem("token"));
//         const role = window.localStorage.getItem("role");

//         if (token) {
//           dispatch({
//             type: "INITIALISE",
//             payload: {
//               isAuthenticated: true,
//               user: null,
//               role,
//             },
//           });
//         } else {
//           dispatch({
//             type: "INITIALISE",
//             payload: {
//               isAuthenticated: false,
//               user: null,
//               role: null,
//             },
//           });
//         }
//       } catch (err) {
//         console.error("Error initialising auth context:", err);
//         dispatch({
//           type: "INITIALISE",
//           payload: {
//             isAuthenticated: false,
//             user: null,
//             role: null,
//           },
//         });
//       }
//     };
//     initialise();
//   }, [state.user, state.role]);


//   if (!state.isInitialised) {
//     return <SplashScreen />;
//   }

//   return (
//     <AuthContext.Provider value={{ ...state, login, logout, register, activateProfile, requestOTP, validateOTP, resetPassword, requestDemo, validateDemo, useractivateProfile }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContext;