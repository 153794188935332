import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, isInitialised } = useAuth();
  if (isInitialised && isAuthenticated) {
    return <Navigate to="/home" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
