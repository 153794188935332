import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import "./Report.css";
import logo from "../../../assets/RVlogo.svg";
//import logo from "../../../assets/2.png";
//import theLogo from '../../../assets/Roadvision.png';
//import theLogo from '../../../assets/logoup.png';
import { MdCalendarMonth } from "react-icons/md";
import { RiEdit2Line } from "react-icons/ri";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from "axios";
import LoadingScreen from "../../../utils/LoadingScreen";
import { IoMapSharp } from "react-icons/io5";
import { PiVideoDuotone } from "react-icons/pi";
import { RiCalculatorFill, RiArticleFill, RiFileDownloadFill, RiHistoryFill, RiNotification3Fill, RiTrafficLightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Skeleton } from 'antd';
import { Progress } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { TextField, InputAdornment } from "@mui/material";
import AuthContext from "../../../contexts/AuthContext";

function createData(
  number,
  Chainage,
  start_address,
  start_latitude,
  start_longitude,
  end_address,
  end_latitude,
  end_longitude,
  Potholes_Count,
  Potholes_Severity,
  Patching_Count,
  Patching_Severity,
  Cracking_Count,
  Cracking_Severity,
  Ravelling_Count,
  Ravelling_Severity,
  Rut_Depth_Count,
  Rut_Depth_Severity,
  Settlements_Count,
  Settlements_Severity,
  Shoving_Count,
  Shoving_Severity,
  IRC_Rating,
) {
  let ret = {
    number,
    Chainage,
    start_address,
    start_latitude,
    start_longitude,
    end_address,
    end_latitude,
    end_longitude,
    Potholes_Count,
    Potholes_Severity,
    Patching_Count,
    Patching_Severity,
    Cracking_Count,
    Cracking_Severity,
    Ravelling_Count,
    Ravelling_Severity,
    Rut_Depth_Count,
    Rut_Depth_Severity,
    Settlements_Count,
    Settlements_Severity,
    Shoving_Count,
    Shoving_Severity,
    IRC_Rating,
  };
  return ret;
}

const setupTableData = (reportTableData) => {
  try {
    console.log('Report Table Data:', reportTableData);
    let data;
    if (typeof reportTableData === 'string') {
      data = JSON.parse(reportTableData);
    } else {
      data = reportTableData;
    }
    console.log(data);
    let totals = {
      Potholes_Count: 0,
      Patching_Count: 0,
      Cracking_Count: 0,
      Ravelling_Count: 0,
      Rut_Depth_Count: 0,
      Settlements_Count: 0,
      Shoving_Count: 0,
    };

    let ret_rows = [];
    Object.values(data).forEach((ele, ind, arr) => {
      delete ele[""];
      let currentChainage = parseInt(ele.Chainage, 10) || 0;
      let nextChainage = currentChainage + 100;

      // Update Chainage to represent range
      ele.Chainage = `${currentChainage}-${nextChainage}`;
      totals.Potholes_Count += parseInt(ele.Potholes_Count, 10) || 0;
      totals.Patching_Count += parseInt(ele.Patching_Count, 10) || 0;
      totals.Cracking_Count += parseInt(ele.Cracking_Count, 10) || 0;
      totals.Ravelling_Count += parseInt(ele.Ravelling_Count, 10) || 0;
      totals.Rut_Depth_Count += parseInt(ele.Rut_Depth_Count, 10) || 0;
      totals.Settlements_Count += parseInt(ele.Settlements_Count, 10) || 0;
      totals.Shoving_Count += parseInt(ele.Shoving_Count, 10) || 0;

      ret_rows.push(createData(...Object.values(ele)));
    });

    console.log("Totals:", totals);
    return { rows: ret_rows, totals };
  } catch (error) {
    console.error('Error parsing reportTableData:', error);
    return { rows: [], totals: {} };
  }
};

const getPieChartData2 = (plotsData) => {
  if (!plotsData || typeof plotsData !== 'object') {
    console.error("Invalid data format:", plotsData);
    return {
      series: [],
      labels: [],
    };
  }
  const labels = Object.values(plotsData);
  const series = Object.keys(plotsData).map(Number);
  return { series, labels };
};

const prepareBarChartData = (reportData) => {

  const categories = [];
  const seriesData = {
    Potholes: [],
    Patching: [],
    Cracking: [],
    Ravelling: [],
    Rut_Depth: [],
    Settlements: [],
    Shoving: [],
  };

  reportData.forEach((item, index) => {
    //categories.push(item.Chainage);
    const chainageStart = index * 100;
    const chainageEnd = (index + 1) * 100;
    categories.push(`${chainageStart}-${chainageEnd}`);
    seriesData.Potholes.push(item.Potholes_Count);
    seriesData.Patching.push(item.Patching_Count);
    seriesData.Cracking.push(item.Cracking_Count);
    seriesData.Ravelling.push(item.Ravelling_Count);
    seriesData.Rut_Depth.push(item.Rut_Depth_Count);
    seriesData.Settlements.push(item.Settlements_Count);
    seriesData.Shoving.push(item.Shoving_Count);
  });

  // Convert seriesData object into ApexCharts series format
  const series = Object.entries(seriesData).map(([name, data]) => ({
    name,
    data,
  }));

  return { categories, series };
};





const Reports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [allTables, setAllTables] = useState([]);
  const [reportTableData, setReportTableData] = useState({});
  const [defectTotals, setDefectTotals] = useState({});
  const [open, setOpen] = useState(false);
  const [pieChart2Data, setpieChart2Data] = useState({});
  const [chartData, setChartData] = useState({ series: [], categories: [] });
  const [editField, setEditField] = useState(null); // Currently edited field
  const [fieldValues, setFieldValues] = useState({});
  const { role } = useContext(AuthContext);
  useEffect(() => {
    const loadReportData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}project/get_plot_data`,
          {
            project_uid_list: JSON.parse(
              decodeURIComponent(searchParams.get("listOfIds"))
            ),
          }
        );
        let responseData = response["data"]["result"];
        let plotsData = responseData["data"]["plots"];
        setpieChart2Data(getPieChartData2(plotsData["pie_chart2"]));
        setAllTables(responseData["report_table_data"]);
        const initialValues = {};
        responseData.report_table_data.forEach((tableData, index) => {
          initialValues[`project_title_${index}`] = tableData.project_title_display;
          initialValues[`start_add_${index}`] = tableData.start_add.add;
          initialValues[`end_add_${index}`] = tableData.end_add.add;
          initialValues[`road_length_${index}`] = tableData.road_length;
        });
        setFieldValues(initialValues);
        const { rows, totals } = setupTableData(responseData["report_table_data"][0]["table"]);
        setReportTableData(rows);
        setDefectTotals(totals);
        const preparedData = prepareBarChartData(rows);
        setChartData({
          series: preparedData.series,
          categories: preparedData.categories,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        console.log("API Error, Contact Admin");
      }
    };
    loadReportData();
  }, [searchParams]);

  const handleEditClick = (field, currentValue) => {
    setEditField(field);
    setFieldValues({
      ...fieldValues,
      [field]: currentValue,
    });
  };

  const handleInputChange = (event, field) => {
    setFieldValues({
      ...fieldValues,
      [field]: event.target.value,
    });
  };

  const handleSave = () => {
    // Save the data, e.g., send to an API
    setEditField(null);
    // Optionally, handle API call and update the allTables state
  };


  const totalDefectCount = Object.values(defectTotals).reduce((acc, current) => acc + current, 0);
  const getProgressColor = (rating) => {
    if (rating === 0) {
      return '#D71E10';
    } else if (rating <= 40) {
      return '#D71E10';
    } else if (rating <= 80) {
      return '#FD9900';
    } else {
      return '#17AB40';
    }
  };
  const getRoadLevel = (rating) => {
    if (rating <= 40) {
      return '(Poor)';
    } else if (rating <= 80) {
      return '(Fair)';
    } else {
      return '(Good)';
    }
  };
  const getRoadLevel2 = (rating) => {
    if (rating <= 40) {
      return 'LEVEL 3';
    } else if (rating <= 80) {
      return 'LEVEL 2';
    } else {
      return 'LEVEL 1';
    }
  };

  const colorsMap = {
    "High Severity": "#E91E63", // Red
    "Medium Severity": "#FF9800", // Orange
    "Not Defected": "#2E93fA", // Blue
    "Low Severity": "#66DA26" // Green
  };

  const colors = pieChart2Data.labels ? pieChart2Data.labels.map(label => colorsMap[label]) : [];


  const options = {
    chart: {
      type: 'pie',
      width: 380,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    labels: pieChart2Data.labels || [],
    colors: colors,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'start',
          fontSize: '8px',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                total: {
                  show: true,
                  label: 'Total',
                }
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} defects`
          }
        },
      }
    }]
  };


  const options2 = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      marginBottom: '5px',
      fontSize: '10px',
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} defects`
      }
    }
  };

  const customFormat = (rating) => (
    <div className="flex flex-col items-center justify-between">
      <div className="text-sm font-semibold text-gray-500">{getRoadLevel2(rating)}</div>
      <div className="text-lg font-bold text-gray-800">{rating}</div>
    </div>
  );


  function containsMultipleIds(listOfIds) {
    try {
      const ids = JSON.parse(listOfIds);
      return Array.isArray(ids) && ids.length > 1;
    } catch (error) {
      return false;
    }
  }
  const listOfIds = searchParams.get("listOfIds");
  const menus = [
    {
      name: "Map",
      link:
        `${window.location.origin.toString()}/pavement/multiMap?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: IoMapSharp,
    },
    {
      name: "Report",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/report?listOfIds=${listOfIds}`,
      icon: RiArticleFill,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Video Inspection",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/video?listOfIds=${listOfIds}`,
      icon: PiVideoDuotone,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    // {
    //   name: "Safety Report",
    //   link: containsMultipleIds(listOfIds)
    //     ? "#"
    //     : `${window.location.origin.toString()}/safetyReports?listOfIds=${listOfIds}`,
    //   icon: FaRegFileLines,
    //   onClick: containsMultipleIds(listOfIds)
    //     ? (event) => {
    //       event.preventDefault();
    //       alert("Please select only one project.");
    //     }
    //     : undefined,
    // },
    {
      name: "Notifications",
      link:
        `${window.location.origin.toString()}/pavement/notifications?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiNotification3Fill,
    },
    {
      name: "VersionControl",
      link: "#",
      icon: RiHistoryFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "Budget Calculator",
      link:
        `${window.location.origin.toString()}/pavement/calculator?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiCalculatorFill,

    },
    {
      name: "Download Report",
      link: "#",
      icon: RiFileDownloadFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },

    },
    {
      name: "Traffic Management System",
      link:
        `${window.location.origin.toString()}/pavement/traffic?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiTrafficLightLine,
    },
  ];
  return (
    <div className="w-full">
      <div
        className="bg-[#F5FAFF] h-full w-18
           text-[#425466] px-4 z-100 fixed"
      >
        <div className="mt-4 flex flex-col gap-4 relative">
          <Link to="/pavement/app" className="mb-2">
            <img src={logo} alt="Logo" className="w-10 h-10 cursor-pointer" />
          </Link>
          {menus?.map((menu, i) => {
            return (
              <Link
                to={menu.link}
                key={i}
                className={` ${menu?.margin && "mt-5"
                  } group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
                title={menu.name}
                onClick={menu.onClick}
              >
                <div>{React.createElement(menu?.icon, { size: "21" })}</div>
              </Link>
            );
          })}
        </div>
      </div>

      {isLoading ? (
        <div className="">
          <LoadingScreen />
        </div>
      ) : (
        <div className="flex flex-col h-screen overflow-hidden ml-[70px] w-[94%] bg-white">
          <div className="flex w-full justify-around px-8 items-center h-16 shadow-md fixed top-0 z-40 bg-white">
            <div className="center_profile">
              <p className="text-lg font-semibold text-gray-800">RoadVision AI Road Inspection Report</p>
            </div>
            <div className="right_profile">
              <div className="flex gap-2">
                <div className="flex items-center">
                  <MdCalendarMonth size="20" />
                </div>
                <div className="flex items-center">
                  {allTables.map((tableData) => (
                    <p className="text-sm text-gray-800">
                      {isLoading ? <Skeleton.Input active size='small' /> :
                        <p>{tableData.created}</p>
                      }
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="header flex flex-col mt-16 overflow-auto p-8">
            <div className="flex gap-4 w-full items-center max-w-screen-l mx-auto">
              <div className="dashboard-box w-full lg:w-1/3 bg-white shadow-md border border-gray-300 flex items-center justify-between">
                <div className="flex flex-col w-full gap-8">
                  <div className="flex flex-col gap-4 bg-white p-6 shadow-md border border-gray-200">
                    <p className="text-lg font-semibold text-gray-800">Overview</p>
                    {allTables.map((tableData, index) => (
                      <div key={index} className="flex flex-col gap-4">
                        {editField === `project_title_${index}` ? (
                          <TextField
                            fullWidth
                            type="text"
                            value={fieldValues[`project_title_${index}`]}
                            onChange={(e) => handleInputChange(e, `project_title_${index}`)}
                            autoFocus
                            onBlur={handleSave}
                            className="bg-[#F0F5FE]"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div className="flex space-x-4">
                                    <div className="text" style={{ color: '#1967D2' }}>Save</div>
                                  </div>
                                </InputAdornment>

                              ),
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 40,
                                borderRadius: 2,
                              },
                              '& .MuiInputBase-input': {
                                padding: '8px 2px',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'transparent',
                                  borderRadius: 2,
                                },
                                '&:hover fieldset': {
                                  borderColor: 'black',
                                  borderRadius: 2,
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#80d4ff',
                                  borderRadius: 2,
                                },
                              },
                            }}
                          />
                        ) : (
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-semibold text-gray-500">
                              Road Name
                              <span className="text-gray-800 ml-2">{fieldValues[`project_title_${index}`]}</span>
                            </p>
                            <button
                              className="ml-2 text-[#1967D2]"
                              onClick={() => handleEditClick(`project_title_${index}`, tableData.project_title_display)}
                            >
                              <RiEdit2Line />
                            </button>
                          </div>
                        )}
                        {editField === `start_add_${index}` ? (
                          <TextField
                            fullWidth
                            type="text"
                            value={fieldValues[`start_add_${index}`]}
                            onChange={(e) => handleInputChange(e, `start_add_${index}`)}
                            autoFocus
                            onBlur={handleSave}
                            className="bg-[#F0F5FE]"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div className="flex space-x-4">
                                    <div className="text" style={{ color: '#1967D2' }}>Save</div>
                                  </div>
                                </InputAdornment>

                              ),
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 40,
                                borderRadius: 2,
                              },
                              '& .MuiInputBase-input': {
                                padding: '8px 2px',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'transparent',
                                  borderRadius: 2,
                                },
                                '&:hover fieldset': {
                                  borderColor: 'black',
                                  borderRadius: 2,
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#80d4ff',
                                  borderRadius: 2,
                                },
                              },
                            }}
                          />
                        ) : (
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-semibold text-gray-500">
                              Starting Point
                              <span className="text-gray-800 ml-2">{fieldValues[`start_add_${index}`]}</span>
                            </p>
                            <button
                              className="ml-2 text-[#1967D2]"
                              onClick={() => handleEditClick(`start_add_${index}`, tableData.start_add.add)}
                            >
                              <RiEdit2Line />
                            </button>
                          </div>
                        )}
                        {editField === `end_add_${index}` ? (
                          <TextField
                            fullWidth
                            type="text"
                            value={fieldValues[`end_add_${index}`]}
                            onChange={(e) => handleInputChange(e, `end_add_${index}`)}
                            autoFocus
                            onBlur={handleSave}
                            className="bg-[#F0F5FE]"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div className="flex space-x-4">
                                    <div className="text" style={{ color: '#1967D2' }}>Save</div>
                                  </div>
                                </InputAdornment>

                              ),
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 40,
                                borderRadius: 2,
                              },
                              '& .MuiInputBase-input': {
                                padding: '8px 2px',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'transparent',
                                  borderRadius: 2,
                                },
                                '&:hover fieldset': {
                                  borderColor: 'black',
                                  borderRadius: 2,
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#80d4ff',
                                  borderRadius: 2,
                                },
                              },
                            }}
                          />
                        ) : (
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-semibold text-gray-500">
                              Ending Point
                              <span className="text-gray-800 ml-2">{fieldValues[`end_add_${index}`]}</span>
                            </p>
                            <button
                              className="ml-2 text-[#1967D2]"
                              onClick={() => handleEditClick(`end_add_${index}`, tableData.end_add.add)}
                            >
                              <RiEdit2Line />
                            </button>
                          </div>
                        )}
                        <p className="text-sm font-semibold text-gray-500">
                          Road Length
                          <span className="text-gray-800 ml-2">{tableData.road_length} KM</span>
                        </p>
                      </div>
                    ))}


                  </div>
                </div>
              </div>
              <div className="dashboard-box w-2/3 bg-white shadow-md border border-gray-300 flex items-center">
                <div className="flex flex-col w-2/3">
                  <div className="flex flex-col gap-4 bg-white p-4 shadow-md border border-gray-200">
                    <p className="text-lg font-semibold text-gray-800">Defect Breakdown</p>
                    <div className="flex flex-col mb-2">
                      <div className="flex flex-wrap gap-8">
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Potholes</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Potholes_Count}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Patching</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Patching_Count}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Cracking</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Cracking_Count}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Ravelling</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Ravelling_Count}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Rutdepth</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Rut_Depth_Count}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Settlements</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Settlements_Count}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-gray-500">Shoving</p>
                          <p className="text-sm font-semibold text-gray-500">{defectTotals.Shoving_Count}</p>
                        </div>
                      </div>
                    </div>
                    <p className="text-lg font-semibold text-gray-800">Total Defect
                      <span className="text-gray-800 ml-2">
                        {totalDefectCount}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex flex-col w-1/3">
                  <div className="bg-gray-100 p-4 shadow-md border border-gray-200">
                    <p className="text-lg font-semibold text-gray-800 mb-8">IRC Rating</p>
                    {allTables.map((tableData, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <Progress
                          type="circle"
                          percent={tableData.road_rating === 0 ? 100 : tableData.road_rating * 33.33}
                          strokeColor={getProgressColor(tableData.road_rating)}
                          format={() => customFormat(tableData.road_rating)}
                          size={150}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-4 gap-4 w-full items-center">
              <div className="w-full bg-white p-4 rounded-md shadow-md border border-gray-300">
                <div className="box w-1/2 h-1/2 float-left border-r border-gray-300">
                  <p className="text-small font-semibold text-gray-800">Severity Breakdown</p>
                  {isLoading ? <Skeleton.Image active /> :
                    <ReactApexChart options={options} series={pieChart2Data.series} type="pie" />
                  }
                </div>
                <div className="box w-1/2 h-1/2 float-left border-gray-300">
                  <p className="text-small font-semibold text-gray-800">Distribution of Defects per Chainage</p>
                  {isLoading ? <Skeleton.Image active /> :
                    <ReactApexChart options={options2} series={chartData.series} type="bar" />
                  }

                </div>
              </div>
            </div>
            <div className="flex mt-4 gap-4 w-full items-center">
              <div className="defect-table-box w-full flex flex-col bg-white p-4 rounded-md shadow-md border border-gray-300">
                <p className="text-small mb-4 font-semibold text-gray-800">Road Analysis</p>
                {isLoading ? <Skeleton active /> :
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Serial No.</TableCell>
                          <TableCell>Chainage(m)</TableCell>
                          <TableCell>Start Address(Lat & Long)</TableCell>
                          <TableCell>End Address(Lat & Long)</TableCell>
                          <TableCell>Defect</TableCell>
                          <TableCell>Road Rating</TableCell>
                          <TableCell>IRC Rating</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportTableData.map((row, index) => (
                          <React.Fragment key={index}>
                            <TableRow
                              key={row.number}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.number}
                              </TableCell>
                              <TableCell>{row.Chainage}</TableCell>
                              <TableCell>
                                <div>{row.start_address}</div>
                                <div>({row.start_latitude}, {row.start_longitude})</div>
                              </TableCell>
                              <TableCell>
                                <div>{row.end_address}</div>
                                <div>({row.end_latitude}, {row.start_longitude})</div>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  size="small"
                                  onClick={() => setOpen(open === index ? false : index)}
                                >
                                  {open === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                <div className="flex items-center gap-2">
                                  <Progress type="circle" percent={row.IRC_Rating === 0 ? 100 : row.IRC_Rating * 33.33} strokeColor={getProgressColor(row.IRC_Rating)} size={20} />
                                  {/* {row.IRC_Rating} */}
                                  {getRoadLevel(row.IRC_Rating)}
                                </div>
                              </TableCell>
                              <TableCell>{row.IRC_Rating}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                <Collapse in={open === index} timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                    <Table size="small" aria-label="details">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Defect Type</TableCell>
                                          <TableCell>Count</TableCell>
                                          <TableCell>Severity</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>Potholes</TableCell>
                                          <TableCell>{row.Potholes_Count}</TableCell>
                                          <TableCell>{row.Potholes_Severity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Patching</TableCell>
                                          <TableCell>{row.Patching_Count}</TableCell>
                                          <TableCell>{row.Patching_Severity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Cracking</TableCell>
                                          <TableCell>{row.Cracking_Count}</TableCell>
                                          <TableCell>{row.Cracking_Severity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Ravelling</TableCell>
                                          <TableCell>{row.Ravelling_Count}</TableCell>
                                          <TableCell>{row.Ravelling_Severity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Rut Depth</TableCell>
                                          <TableCell>{row.Rut_Depth_Count}</TableCell>
                                          <TableCell>{row.Rut_Depth_Severity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Settlements</TableCell>
                                          <TableCell>{row.Settlements_Count}</TableCell>
                                          <TableCell>{row.Settlements_Severity}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Shoving</TableCell>
                                          <TableCell>{row.Shoving_Count}</TableCell>
                                          <TableCell>{row.Shoving_Severity}</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;