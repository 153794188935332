import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import "tailwindcss/tailwind.css";
import { AuthProvider } from "./contexts/AuthContext";
import '@fontsource/inter';
const App = () => {
  return (
    <>
      <Router>
        <AuthProvider>
          <div className="app">{renderRoutes(routes)}</div>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
