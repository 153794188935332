// import React, { useState, useEffect, useContext, useRef } from "react";
// import { Select } from 'antd';
// import { Form, Input, Table, Checkbox, Pagination, Button } from 'antd';
// import { CarOutlined } from '@ant-design/icons';
// import { MdCurrencyRupee } from "react-icons/md";
// import { Card } from 'antd';
// import { useSearchParams } from "react-router-dom";
// import logo from "../../assets/2.png";
// //import logo from "../../assets/RVlogo.svg";
// import nodata from "../../assets/nodata.svg";
// import axios from "axios";
// import { Skeleton } from 'antd';
// import { IoMapSharp } from "react-icons/io5";
// import { RiCalculatorFill, RiArticleFill, RiFileDownloadFill, RiHistoryFill, RiNotification3Fill, RiTrafficLightLine } from "react-icons/ri";
// import { Link } from "react-router-dom";
// import "./Calculator.css";

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
//   getCheckboxProps: (record) => ({
//     name: record.name,
//   }),
// };
// const EditableContext = React.createContext(null);
// const EditableRow = ({ index, ...props }) => {
//   const [form] = Form.useForm();
//   return (
//     <Form form={form} component={false}>
//       <EditableContext.Provider value={form}>
//         <tr {...props} />
//       </EditableContext.Provider>
//     </Form>
//   );
// };
// const EditableCell = ({
//   title,
//   editable,
//   children,
//   dataIndex,
//   record,
//   handleSave,
//   ...restProps
// }) => {
//   const [editing, setEditing] = useState(false);
//   const inputRef = useRef(null);
//   const form = useContext(EditableContext);
//   useEffect(() => {
//     if (editing) {
//       inputRef.current.focus();
//     }
//   }, [editing]);
//   const toggleEdit = () => {
//     setEditing(!editing);
//     form.setFieldsValue({
//       [dataIndex]: record[dataIndex],
//     });
//   };
//   const save = async () => {
//     try {
//       const values = await form.validateFields();
//       toggleEdit();
//       handleSave({
//         ...record,
//         ...values,
//       });
//     } catch (errInfo) {
//       console.log('Save failed:', errInfo);
//     }
//   };
//   let childNode = children;
//   if (editable) {
//     childNode = editing ? (
//       <Form.Item
//         style={{
//           margin: 0,
//         }}
//         name={dataIndex}
//         rules={[
//           {
//             required: true,
//             message: `${title} is required.`,
//           },
//         ]}
//       >
//         <Input ref={inputRef} onPressEnter={save} onBlur={save} />
//       </Form.Item>
//     ) : (
//       <div
//         className="editable-cell-value-wrap"
//         style={{
//           paddingRight: 24,
//         }}
//         onClick={toggleEdit}
//       >
//         {children}
//       </div>
//     );
//   }
//   return <td {...restProps}>{childNode}</td>;
// };

// const Calculator = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [defectDetails, setDefectDetails] = useState([]);
//   const [error, setError] = useState(null);
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [selectionType, setSelectionType] = useState('checkbox');
//   const [list, setList] = useState([]);
//   const [dataSource, setDataSource] = useState([]);
//   const [totalRoadLength, setTotalRoadLength] = useState(0);


//   const defaultColumns = [
//     {
//       title: 'Defects',
//       dataIndex: 'defects',
//       // eslint-disable-next-line jsx-a11y/anchor-is-valid
//       render: (text) => <a>{text}</a>,
//       width: '10%',
//     },
//     {
//       title: 'Level',
//       dataIndex: 'level',
//       width: '50%',
//       render: (text, record) => (
//         <div>
//           <Checkbox
//             id={`lowCheckbox_${record.key}`}
//             value="low"
//             onChange={(e) => handleCheckboxChange(record.key, 'low', e.target.checked)}
//             checked={record.selectedLevels ? record.selectedLevels.includes('low') : true}
//           >
//             Low
//           </Checkbox>
//           <Checkbox
//             id={`mediumCheckbox_${record.key}`}
//             value="medium"
//             onChange={(e) => handleCheckboxChange(record.key, 'medium', e.target.checked)}
//             checked={record.selectedLevels ? record.selectedLevels.includes('medium') : true}
//           >
//             Medium
//           </Checkbox>
//           <Checkbox
//             id={`highCheckbox_${record.key}`}
//             value="high"
//             onChange={(e) => handleCheckboxChange(record.key, 'high', e.target.checked)}
//             checked={record.selectedLevels ? record.selectedLevels.includes('high') : true}
//           >
//             High
//           </Checkbox>
//         </div>
//       ),
//     },

//     {
//       title: 'Found',
//       dataIndex: 'found',
//       width: '10%',
//       render: (text, record) => {
//         let totalFound = 0;
//         if (record.selectedLevels) {
//           record.selectedLevels.forEach((level) => {
//             totalFound += record.found[level] || 0;
//           });
//         }
//         return <div>{totalFound}</div>;
//       },
//     },
//     {
//       title: 'Est. Cost',
//       dataIndex: 'estimated_cost',
//       editable: true,
//       width: '10%',
//     },
//     {
//       title: 'Cost',
//       dataIndex: 'cost',
//       width: '10%',
//     },
//     {
//       title: 'Total',
//       dataIndex: 'total',
//       width: '10%',
//     }
//   ];

//   const handleCheckboxChange = (key, level, isChecked) => {
//     console.log(`Checkbox Change: Key=${key}, Level=${level}, Checked=${isChecked}`);

//     setDataSource(currentDataSource =>
//       currentDataSource.map(item => {
//         if (item.key === key) {
//           const updatedSelectedLevels = isChecked
//             ? [...item.selectedLevels, level]
//             : item.selectedLevels.filter(l => l !== level);

//           console.log(`Updated Selected Levels for Key=${key}:`, updatedSelectedLevels);
//           return { ...item, selectedLevels: updatedSelectedLevels };
//         }
//         return item;
//       })
//     );
//   };

//   const [totalEstimateBudget, setTotalEstimateBudget] = useState(0);
//   const handleDefectSelection = (selectedRowKeys, selectedRows) => {
//     const updatedDataSource = dataSource.map((item) => {
//       if (selectedRowKeys.includes(item.key)) {
//         const totalFound = item.selectedLevels.reduce((total, level) => total + item.found[level], 0);
//         item.cost = item.estimated_cost * totalFound;
//         item.total = item.cost;
//       } else {
//         item.cost = 0;
//         item.total = 0;
//       }
//       return item;
//     });

//     setDataSource(updatedDataSource);
//     const newTotalEstimateBudget = updatedDataSource.reduce(
//       (total, item) => total + item.total,
//       0
//     );
//     setTotalEstimateBudget(newTotalEstimateBudget);
//   };
//   const handleSave = async (row) => {
//     try {
//       const newData = [...dataSource];
//       const index = newData.findIndex((item) => row.key === item.key);
//       const item = newData[index];
//       newData.splice(index, 1, {
//         ...item,
//         ...row,
//         cost: row.estimated_cost * row.found,
//         total: row.estimated_cost * row.found,
//       });
//       setDataSource(newData);
//     } catch (errInfo) {
//       console.log('Save failed:', errInfo);
//     }
//   };

//   const components = {
//     body: {
//       row: EditableRow,
//       cell: EditableCell,
//     },
//   };
//   const columns = defaultColumns.map((col) => {
//     if (!col.editable) {
//       return col;
//     }
//     return {
//       ...col,
//       onCell: (record) => ({
//         record,
//         editable: col.editable,
//         dataIndex: col.dataIndex,
//         title: col.title,
//         handleSave,
//       }),
//     };
//   });
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}project/get_defect`
//         );
//         const tempUIDArray = response.data.result.map((item, index) => ({
//           uid: `${item.uuid}-${index}`,
//           project_name: item.project_name,
//           defect_details: item.defect_details,
//           road_length: item.road_length,
//         }));
//         setList(tempUIDArray.map((item) => item.project_name));
//         setDefectDetails(tempUIDArray);
//         setIsLoading(false);
//       } catch (error) {
//         setError(error.message || 'An error occurred');
//         setIsLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const transformData = (defectDetails) => {
//     const defectsMap = {};
//     const estimatedCosts = {
//       Raveling: 900,
//       Rut: 1000,
//       Crack: 1200,
//       Pothole: 1500,
//       Shoving: 2000,
//       Settlement: 2500,
//       Patching: 1300,
//     };
//     defectDetails.forEach((item) => {
//       const { name, level, value } = item;
//       if (!defectsMap[item.name]) {
//         defectsMap[item.name] = {
//           defects: item.name,
//           estimated_cost: estimatedCosts[name] || 900,
//           found: {
//             low: 0,
//             medium: 0,
//             high: 0,
//           },
//           selectedLevels: ['low', 'medium', 'high'],
//         };
//       }
//       const defectLevel = level.toLowerCase();
//       defectsMap[name].found[defectLevel] += value;
//     });
//     return Object.values(defectsMap).map((item, index) => ({
//       ...item,
//       key: `${item.defects}-${index}`
//     }));
//   };
//   const handleProjectSelection = (selectedUIDs) => {
//     const selectedProjects = defectDetails.filter((item) => selectedUIDs.includes(item.uid));
//     let totalRoadLength = selectedProjects.reduce((sum, project) => {
//       return sum + project.road_length;
//     }, 0);
//     totalRoadLength = parseFloat(totalRoadLength.toFixed(2));
//     setTotalRoadLength(totalRoadLength);
//     const selectedDefects = selectedProjects.flatMap((item) => item.defect_details);
//     const transformedData = transformData(selectedDefects);
//     setDataSource(transformedData);
//   };

//   function containsMultipleIds(listOfIds) {
//     try {
//       const ids = JSON.parse(listOfIds);
//       return Array.isArray(ids) && ids.length > 1;
//     } catch (error) {
//       return false;
//     }
//   }
//   const listOfIds = searchParams.get("listOfIds");
//   const menus = [
//     {
//       name: "Map",
//       link:
//         `${window.location.origin.toString()}/multiMap?listOfIds=` +
//         searchParams.get("listOfIds"),
//       icon: IoMapSharp,
//     },
//     {
//       name: "Report",
//       link: containsMultipleIds(listOfIds) 
//         ? "#" 
//         : `${window.location.origin.toString()}/report?listOfIds=${listOfIds}`,
//       icon: RiArticleFill,
//       onClick: containsMultipleIds(listOfIds) 
//         ? (event) => {
//           event.preventDefault();
//           alert("Please select only one project.");
//         } 
//         : undefined,
//     },
//     {
//       name: "Notifications",
//       link: "#",
//       icon: RiNotification3Fill,
//       onClick: (event) => {
//         event.preventDefault();
//         alert("Only Available For Enterprise Customers!");
//       },

//     },
//     {
//       name: "VersionControl",
//       link: "#",
//       icon: RiHistoryFill,
//       onClick: (event) => {
//         event.preventDefault();
//         alert("Only Available For Enterprise Customers!");
//       },
//     },
//     {
//       name: "Budget Calculator",
//       link:
//         `${window.location.origin.toString()}/calculator?listOfIds=` +
//         searchParams.get("listOfIds"),
//       icon: RiCalculatorFill,

//     },
//     {
//       name: "Download Report",
//       link: "#",
//       icon: RiFileDownloadFill,
//       onClick: (event) => {
//         event.preventDefault();
//         alert("Only Available For Enterprise Customers!");
//       },

//     },
//     {
//       name: "Traffic Management System",
//       link:
//         `${window.location.origin.toString()}/traffic?listOfIds=` +
//         searchParams.get("listOfIds"),
//       icon: RiTrafficLightLine,
//     },
//   ];

//   const handleRowSelection = (selectedRowKeys, selectedRows) => {
//     setSelectedItems(selectedRowKeys);
//     handleProjectSelection(selectedRowKeys);
//   };

//   const handleSelectAll = () => {
//     const allRoadKeys = defectDetails.map(item => item.uid);
//     setSelectedItems(allRoadKeys);
//     handleProjectSelection(allRoadKeys);
//   };

//   const handleDeselectAll = () => {
//     setSelectedItems([]);
//     handleProjectSelection([]);
//   };

//   const rowroadSelection = {
//     selectedRowKeys: selectedItems,
//     onChange: handleRowSelection,
//   };



//   const roadname = [
//     {
//       title: 'Road Name',
//       dataIndex: 'project_name',
//       key: 'project_name',
//     },
//   ];

//   const [currentPage, setCurrentPage] = useState(1);
//   const pageSize = 7;
//   const [searchText, setSearchText] = useState('');
//   const [filteredData, setFilteredData] = useState(defectDetails);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setSearchText(value);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     const filtered = defectDetails.filter(item =>
//       item.project_name.toLowerCase().includes(searchText.toLowerCase())
//     );
//     setFilteredData(filtered);
//   }, [searchText, defectDetails]);

//   const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(item => ({
//     ...item,
//     key: item.uid,
//   }));


//   return (
//     <div className="w-full">
//       <div
//         className="bg-[#F5FAFF] h-full w-18
//            text-[#425466] px-4 z-100 fixed"
//       >
//         <div className="mt-4 flex flex-col gap-4 relative">
//           <Link to="/app" className="mb-2">
//             <img src={logo} alt="Logo" className="w-10 h-10 cursor-pointer" />
//           </Link>
//           {menus?.map((menu, i) => {
//             return (
//               <Link
//                 to={menu.link}
//                 key={i}
//                 className={` ${menu?.margin && "mt-5"
//                   } group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
//                 title={menu.name}
//                 onClick={menu.onClick}
//               >
//                 <div>{React.createElement(menu?.icon, { size: "21" })}</div>
//               </Link>
//             );
//           })}
//         </div>
//       </div>
//       <div className="ml-[70px] w-[94%]">
//         <div className="main">
//           <div className="budget-main">
//             <h1>Budget Calculator</h1>
//             <div className="budget-select">
//               <Card
//                 title={
//                   <div className="flex gap-2 flex-col mt-4 mb-5">
//                     <span>Select the roads you want to repair</span>
//                     <div className="flex gap-2">
//                       <Input
//                         placeholder="🔍 Search for a road"
//                         value={searchText}
//                         onChange={handleSearch}
//                         style={{ width: 200, borderRadius: 15 }}
//                       />
//                       <Button type="dashed" style={{ borderRadius: 15 }} onClick={() => selectedItems.length === defectDetails.length ? handleDeselectAll() : handleSelectAll()}>
//                         {selectedItems.length === defectDetails.length ? 'Deselect All' : 'Select All'}
//                       </Button>
//                     </div>
//                   </div>
//                 }
//                 bordered={true}
//                 style={{ width: 370 }}
//                 actions={[
//                   <Pagination size="small"
//                     current={currentPage}
//                     onChange={handlePageChange}
//                     total={defectDetails.length}
//                     pageSize={pageSize}
//                     showSizeChanger={false}
//                   />
//                 ]}
//               >
//                 {isLoading ? (
//                   <Skeleton active />
//                 ) : (
//                   <Table
//                     rowSelection={{
//                       type: 'checkbox',
//                       ...rowroadSelection,
//                     }}
//                     columns={roadname}
//                     dataSource={paginatedData}
//                     pagination={false}
//                     size="small"
//                   />
//                 )}
//               </Card>
//               <div>
//                 <Card title="Which defects do you wish to repair"
//                   bordered={true}
//                   actions={[<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
//                     <div className="flex items-center gap-2">
//                       Total Road Length <CarOutlined /> {totalRoadLength} km
//                     </div>
//                     <div className="flex items-center gap-2 text-gray-800 font-semibold">
//                       Final Cost<MdCurrencyRupee />
//                       {totalEstimateBudget}
//                     </div>
//                   </div>]}
//                   style={{ width: 750 }}>
//                   <Table
//                     rowSelection={{
//                       type: selectionType,
//                       ...rowSelection,
//                       onChange: handleDefectSelection,
//                     }}
//                     components={components}
//                     rowClassName={() => 'editable-row'}
//                     bordered
//                     dataSource={dataSource}
//                     pagination={false}
//                     columns={columns}
//                     locale={{
//                       emptyText: (
//                         <div className="flex flex-col justify-center items-center text-gray-800">
//                           <img src={nodata} alt="No Data" style={{ width: '120px' }} />
//                           <p>Select roads on the left pane to view and calculate repair cost</p>
//                         </div>
//                       )
//                     }}
//                   />
//                 </Card>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Calculator;




import React, { useState, useEffect, useContext, useRef } from "react";
import { Select, Form, Input, Table, Checkbox, Pagination, Button, Card, Skeleton } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import { MdCurrencyRupee } from "react-icons/md";
import { useSearchParams, Link } from "react-router-dom";
import axios from "axios";
import { IoMapSharp } from "react-icons/io5";
import { PiVideoDuotone } from "react-icons/pi";
import { RiCalculatorFill, RiArticleFill, RiFileDownloadFill, RiHistoryFill, RiNotification3Fill, RiTrafficLightLine } from "react-icons/ri";
import { GoDownload } from "react-icons/go";
import * as XLSX from "xlsx";
import logo from "../../../assets/RVlogo.svg";
import nodata from "../../../assets/nodata.svg";
import "./Calculator.css";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Calculator = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [defectDetails, setDefectDetails] = useState([]);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [list, setList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [totalRoadLength, setTotalRoadLength] = useState(0);
  const [totalEstimateBudget, setTotalEstimateBudget] = useState(0);

  const defaultColumns = [
    {
      title: 'Defects',
      dataIndex: 'defects',
      render: (text) => <a>{text}</a>,
      width: '10%',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      width: '50%',
      render: (text, record) => (
        <div>
          <Checkbox
            id={`lowCheckbox_${record.key}`}
            value="low"
            onChange={(e) => handleCheckboxChange(record.key, 'low', e.target.checked)}
            checked={record.selectedLevels ? record.selectedLevels.includes('low') : true}
          >
            Low
          </Checkbox>
          <Checkbox
            id={`mediumCheckbox_${record.key}`}
            value="medium"
            onChange={(e) => handleCheckboxChange(record.key, 'medium', e.target.checked)}
            checked={record.selectedLevels ? record.selectedLevels.includes('medium') : true}
          >
            Medium
          </Checkbox>
          <Checkbox
            id={`highCheckbox_${record.key}`}
            value="high"
            onChange={(e) => handleCheckboxChange(record.key, 'high', e.target.checked)}
            checked={record.selectedLevels ? record.selectedLevels.includes('high') : true}
          >
            High
          </Checkbox>
        </div>
      ),
    },
    {
      title: 'Found',
      dataIndex: 'found',
      width: '10%',
      render: (text, record) => {
        let totalFound = 0;
        if (record.selectedLevels) {
          record.selectedLevels.forEach((level) => {
            totalFound += record.found[level] || 0;
          });
        }
        return <div>{totalFound}</div>;
      },
    },
    {
      title: 'Est. Cost',
      dataIndex: 'estimated_cost',
      editable: true,
      width: '10%',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      width: '10%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '10%',
    }
  ];

  const handleExport = () => {
    // Convert current dataSource to JSON
    const exportData = dataSource.map(item => {
      return {
        defects: item.defects,
        found: item.selectedLevels.reduce((acc, level) => acc + item.found[level], 0),
        estimated_cost: item.estimated_cost,
        cost: item.cost,
        total: item.total
      };
    });

    // Create a worksheet from the JSON data
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Budget Estimation");

    // Write the workbook and trigger the download
    XLSX.writeFile(workbook, "Budget_Estimation.xlsx");
  };


  const handleCheckboxChange = (key, level, isChecked) => {
    setDataSource(currentDataSource =>
      currentDataSource.map(item => {
        if (item.key === key) {
          const updatedSelectedLevels = isChecked
            ? [...item.selectedLevels, level]
            : item.selectedLevels.filter(l => l !== level);

          return { ...item, selectedLevels: updatedSelectedLevels };
        }
        return item;
      })
    );
  };

  const handleDefectSelection = (selectedRowKeys) => {
    const updatedDataSource = dataSource.map((item) => {
      if (selectedRowKeys.includes(item.key)) {
        const totalFound = item.selectedLevels.reduce((total, level) => total + item.found[level], 0);
        item.cost = item.estimated_cost * totalFound;
        item.total = item.cost;
      } else {
        item.cost = 0;
        item.total = 0;
      }
      return item;
    });

    setDataSource(updatedDataSource);
    const newTotalEstimateBudget = updatedDataSource.reduce(
      (total, item) => total + item.total,
      0
    );
    setTotalEstimateBudget(newTotalEstimateBudget);
  };

  const handleSave = async (row) => {
    try {
      const newData = [...dataSource];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];

      const updatedItem = {
        ...item,
        ...row,
        cost: row.estimated_cost * (item.found.low + item.found.medium + item.found.high),
        total: row.estimated_cost * (item.found.low + item.found.medium + item.found.high),
      };

      newData.splice(index, 1, updatedItem);
      setDataSource(newData);

      const newTotalEstimateBudget = newData.reduce(
        (total, item) => total + item.total,
        0
      );
      setTotalEstimateBudget(newTotalEstimateBudget);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}project/get_defect`
        );
        const tempUIDArray = response.data.result.map((item, index) => ({
          uid: `${item.uuid}-${index}`,
          project_name: item.project_name,
          defect_details: item.defect_details,
          road_length: item.road_length,
        }));
        setList(tempUIDArray.map((item) => item.project_name));
        setDefectDetails(tempUIDArray);
        setIsLoading(false);
      } catch (error) {
        setError(error.message || 'An error occurred');
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const transformData = (defectDetails) => {
    const defectsMap = {};
    const estimatedCosts = {
      Raveling: 900,
      Rut: 1000,
      Crack: 1200,
      Pothole: 1500,
      Shoving: 2000,
      Settlement: 2500,
      Patching: 1300,
    };
    defectDetails.forEach((item) => {
      const { name, level, value } = item;
      if (!defectsMap[item.name]) {
        defectsMap[item.name] = {
          defects: item.name,
          estimated_cost: estimatedCosts[name] || 900,
          found: {
            low: 0,
            medium: 0,
            high: 0,
          },
          selectedLevels: ['low', 'medium', 'high'],
        };
      }
      const defectLevel = level.toLowerCase();
      defectsMap[name].found[defectLevel] += value;
    });
    return Object.values(defectsMap).map((item, index) => ({
      ...item,
      key: `${item.defects}-${index}`
    }));
  };

  const handleProjectSelection = (selectedUIDs) => {
    const selectedProjects = defectDetails.filter((item) => selectedUIDs.includes(item.uid));
    let totalRoadLength = selectedProjects.reduce((sum, project) => {
      return sum + project.road_length;
    }, 0);
    totalRoadLength = parseFloat(totalRoadLength.toFixed(2));
    setTotalRoadLength(totalRoadLength);
    const selectedDefects = selectedProjects.flatMap((item) => item.defect_details);
    const transformedData = transformData(selectedDefects);
    setDataSource(transformedData);
  };

  function containsMultipleIds(listOfIds) {
    try {
      const ids = JSON.parse(listOfIds);
      return Array.isArray(ids) && ids.length > 1;
    } catch (error) {
      return false;
    }
  }
  const listOfIds = searchParams.get("listOfIds");
  const menus = [
    {
      name: "Map",
      link:
        `${window.location.origin.toString()}/pavement/multiMap?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: IoMapSharp,
    },
    {
      name: "Report",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/report?listOfIds=${listOfIds}`,
      icon: RiArticleFill,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Video Inspection",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/video?listOfIds=${listOfIds}`,
      icon: PiVideoDuotone,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Notifications",
      link: "#",
      icon: RiNotification3Fill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "VersionControl",
      link: "#",
      icon: RiHistoryFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "Budget Calculator",
      link:
        `${window.location.origin.toString()}/pavement/calculator?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiCalculatorFill,
    },
    {
      name: "Download Report",
      link: "#",
      icon: RiFileDownloadFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "Traffic Management System",
      link:
        `${window.location.origin.toString()}/pavement/traffic?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiTrafficLightLine,
    },
  ];

  const handleRowSelection = (selectedRowKeys, selectedRows) => {
    setSelectedItems(selectedRowKeys);
    handleProjectSelection(selectedRowKeys);
  };

  const handleSelectAll = () => {
    const allRoadKeys = defectDetails.map(item => item.uid);
    setSelectedItems(allRoadKeys);
    handleProjectSelection(allRoadKeys);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
    handleProjectSelection([]);
  };

  const rowroadSelection = {
    selectedRowKeys: selectedItems,
    onChange: handleRowSelection,
  };

  const roadname = [
    {
      title: 'Road Name',
      dataIndex: 'project_name',
      key: 'project_name',
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 7;
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(defectDetails);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const filtered = defectDetails.filter(item =>
      item.project_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchText, defectDetails]);

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(item => ({
    ...item,
    key: item.uid,
  }));

  const rowSelection = {
    onChange: handleDefectSelection,
    getCheckboxProps: (record) => ({
      disabled: !record.selectedLevels || record.selectedLevels.length === 0,
    }),
  };

  return (
    <div className="w-full">
      <div className="bg-[#F5FAFF] h-full w-18 text-[#425466] px-4 z-100 fixed">
        <div className="mt-4 flex flex-col gap-4 relative">
          <Link to="/pavement/app" className="mb-2">
            <img src={logo} alt="Logo" className="w-10 h-10 cursor-pointer" />
          </Link>
          {menus?.map((menu, i) => {
            return (
              <Link
                to={menu.link}
                key={i}
                className={`${menu?.margin && "mt-5"} group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
                title={menu.name}
                onClick={menu.onClick}
              >
                <div>{React.createElement(menu?.icon, { size: "21" })}</div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="ml-[70px] w-[94%]">
        <div className="main">
          <div className="budget-main">
            <h1>Budget Calculator</h1>
            <div className="budget-select">
              <Card
                title={
                  <div className="flex gap-2 flex-col mt-4 mb-5">
                    <span>Select the roads you want to repair</span>
                    <div className="flex gap-2">
                      <Input
                        placeholder="🔍 Search for a road"
                        value={searchText}
                        onChange={handleSearch}
                        style={{ width: 200, borderRadius: 15 }}
                      />
                      <Button type="dashed" style={{ borderRadius: 15 }} onClick={() => selectedItems.length === defectDetails.length ? handleDeselectAll() : handleSelectAll()}>
                        {selectedItems.length === defectDetails.length ? 'Deselect All' : 'Select All'}
                      </Button>
                    </div>
                  </div>
                }
                bordered={true}
                style={{ width: 370 }}
                actions={[
                  <Pagination size="small"
                    current={currentPage}
                    onChange={handlePageChange}
                    total={defectDetails.length}
                    pageSize={pageSize}
                    showSizeChanger={false}
                  />
                ]}
              >
                {isLoading ? (
                  <Skeleton active />
                ) : (
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowroadSelection,
                    }}
                    columns={roadname}
                    dataSource={paginatedData}
                    pagination={false}
                    size="small"
                  />
                )}
              </Card>
              <div>
                <Card title="Which defects do you wish to repair"
                  bordered={true}
                  actions={[<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <div className="flex items-center gap-2">
                      Total Road Length <CarOutlined /> {totalRoadLength} km
                    </div>
                    <div className="flex items-center gap-2 text-gray-800 font-semibold">
                      Final Cost<MdCurrencyRupee />
                      {totalEstimateBudget}
                    </div>
                    <div className="flex items-center gap-2">
                      <Button type="primary" ghost onClick={handleExport}><GoDownload /></Button>
                    </div>

                  </div>]}
                  style={{ width: 750 }}>
                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    pagination={false}
                    columns={columns}
                    locale={{
                      emptyText: (
                        <div className="flex flex-col justify-center items-center text-gray-800">
                          <img src={nodata} alt="No Data" style={{ width: '120px' }} />
                          <p>Select roads on the left pane to view and calculate repair cost</p>
                        </div>
                      )
                    }}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
