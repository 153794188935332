import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingScreen from "../../../utils/LoadingScreen";
import { MdOutlineDashboard } from "react-icons/md";
import { RiArticleFill } from "react-icons/ri";
import { FaCalculator } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import { GiBackwardTime } from "react-icons/gi";
import { Link } from "react-router-dom";
import premium from "../../../assets/premium2.png";

const VersionControl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function containsMultipleIds(listOfIds) {
    try {
      const ids = JSON.parse(listOfIds);
      return Array.isArray(ids) && ids.length > 1;
    } catch (error) {
      return false;
    }
  }
  const listOfIds = searchParams.get("listOfIds");

  const menus = [
    {
      name: "Map",
      link:
        `${window.location.origin.toString()}/multiMap?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: MdOutlineDashboard,
    },
    {
      name: "Report",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/report?listOfIds=${listOfIds}`,
      icon: RiArticleFill,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Notifications",
      link:
        `${window.location.origin.toString()}/notifications?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: IoNotifications,
    },
    {
      name: "VersionControl",
      link:
        `${window.location.origin.toString()}/versionControl?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: GiBackwardTime,
    },
  ];
  return (
    <div className="w-full">
      <div
        className="bg-[#F5FAFF] h-full w-18
           text-[#425466] px-4 z-100 fixed"
      >
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus?.map((menu, i) => {
            return (
              <Link
                to={menu.link}
                key={i}
                className={` ${menu?.margin && "mt-5"
                  } group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
              >
                <div>{React.createElement(menu?.icon, { size: "21" })}</div>
              </Link>
            );
          })}
        </div>
      </div>

      {isLoading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <div className="ml-[70px] w-[95%] flex flex-col justify-center items-center h-[90vh] gap-8 ">
          <p className="text-5xl font-bold text-[#376190] tracking-wide">
            VERSION CONTROL IS ONLY FOR OUR
          </p>
          <img src={premium} alt="img" width="400" />
        </div>
      )}
    </div>
  );
};

export default VersionControl;