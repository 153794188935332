import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from "axios";
import theLogo from '../../../assets/Roadvision.png';
//import theLogo from '../../../assets/logoup.png';
import area from '../../../assets/area.svg';
import areaui from '../../../assets/areaui.svg';
import defect from '../../../assets/defect.svg';
import defectui from '../../../assets/defectui.svg';
import length from '../../../assets/length.svg';
import lengthui from '../../../assets/lengthui.svg';
import level1 from '../../../assets/level1.svg';
import level2 from '../../../assets/level2.svg';
import level3 from '../../../assets/level3.svg';
import { MdCalendarMonth } from "react-icons/md";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';
import { Progress } from 'antd';
import { Carousel } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';
import './FinalReport.css'
import ds_ss from '../../../assets/ds_ss.png';
//import main_page_ss from '../../assets/main_page_ss.png';
import maps_ss from '../../../assets/maps_ss.png';
//import mount_ss from '../../assets/mount_ss.png';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from 'antd';
const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    const getProgressColor = (rating) => {
        if (rating === 0) {
            return '#D71E10';
        } else if (rating <= 40) {
            return '#D71E10';
        } else if (rating <= 80) {
            return '#FD9900';
        } else {
            return '#17AB40';
        }
    };
    const getRoadLevel = (rating) => {
        if (rating <= 40) {
            return '(Poor)';
        } else if (rating <= 80) {
            return '(Fair)';
        } else {
            return '(Good)';
        }
    };
    const trimAddress = (address) => {
        const parts = address.split(', ');
        const trimmedParts = [];
        if (parts[1]) trimmedParts.push(parts[0]);
        if (parts[4]) trimmedParts.push(parts[3]);
        const trimmedAddress = trimmedParts.join(', ');
        return trimmedAddress;
    };
    const trimmedStart = trimAddress(row.start);
    const trimmedEnd = trimAddress(row.end);
    return (
        <>
            <TableRow>
                <TableCell>{trimmedStart}</TableCell>
                <TableCell>{trimmedEnd}</TableCell>
                <TableCell>{`${row.roadLength.toFixed(2)} KM`}</TableCell>
                <TableCell>
                    <div className="flex items-center gap-2">
                        <Progress type="circle" percent={row.roadRating === 0 ? 100 : row.roadRating * 33.33} strokeColor={getProgressColor(row.roadRating)} size={20} />
                        {row.roadRating}
                        {getRoadLevel(row.roadRating)}
                    </div>
                </TableCell>
                <TableCell>
                    {row.defect}
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <div className="flex flex-wrap gap-2 flex-basis w-full">
                                {row.defectDetails.map((defect, index) => (
                                    <React.Fragment key={index}>
                                        <div className="flex gap-2" style={{ width: '18%' }}>
                                            <span>{`${defect.name} (${defect.level})`}</span>
                                            <span className="font-semibold">{defect.value}</span>
                                        </div>
                                        {(index + 1) % 5 === 0 && <div className="w-full"></div>}
                                    </React.Fragment>
                                ))}
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

Row.propTypes = {
    row: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        roadLength: PropTypes.string.isRequired,
        roadRating: PropTypes.number.isRequired,
        defect: PropTypes.number.isRequired,
        defectDetails: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                level: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};



const FinalReport = () => {
    const [reportKeys, setReportKeys] = useState([]);
    const [overallDefectCount, setOverallDefectCount] = useState(0);
    const [totalRoadLength, setTotalRoadLength] = useState(0);
    const [defects, setDefects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [city, setCity] = useState('');
    const [created, setCreated] = useState('');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [roadStatus, setRoadStatus] = useState({ 1: 0, 2: 0, 3: 0 });
    const calculateLevel = (rating) => {
        if (rating <= 40) {
            return 3;
        } else if (rating <= 80) {
            return 2;
        } else {
            return 1;
        }
    };
    const calculateRoadLengthsByLevel = useCallback(() => {
        const lengths = { 1: 0, 2: 0, 3: 0 };
        reportKeys.forEach((road) => {
            const level = calculateLevel(road.roadRating);
            lengths[level] += parseFloat(road.roadLength);
        });
        return lengths;
    }, [reportKeys]);
    useEffect(() => {
        const lengthsByLevel = calculateRoadLengthsByLevel();
        setRoadStatus(lengthsByLevel);
    }, [calculateRoadLengthsByLevel]);

    const generateUniqueColors = (baseColors, count) => {
        const generatedColors = [];
        const step = Math.floor(360 / count);

        for (let i = 0; i < count; i++) {
            const hue = i * step;
            const color = `hsl(${hue}, 70%, 50%)`;
            generatedColors.push(color);
        }

        return generatedColors;
    };
    const autoGeneratedColors = useMemo(() => {
        const defectColors = ['#7B1FA2', '#2196F3', '#3949AB', '#90CAF9', '#BA68C8', '#4A148C'];
        if (defectColors.length >= reportKeys.length) {
            return defectColors.slice(0, reportKeys.length);
        } else {
            return generateUniqueColors(defectColors, reportKeys.length);
        }
    }, [reportKeys.length]);

    const roadHSettleApexConfig = useMemo(() => {
        const roadData = reportKeys.map(road =>
            road.defectDetails.find(defect => defect.name === 'Settlement' && defect.level === 'High')?.value || 0
        );
        const roadLabels = reportKeys.map(road => road.start);
        return {
            series: roadData,
            options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        },
                    },
                },
                labels: roadLabels,
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'start',
                    fontSize: '8px',
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                                total: {
                                    show: true,
                                    label: 'Total',
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => `${val} defects`
                    }
                },
                colors: autoGeneratedColors
            }
        };
    }, [autoGeneratedColors, reportKeys]);
    const roadHRavelingApexConfig = useMemo(() => {
        const roadData = reportKeys.map(road =>
            road.defectDetails.find(defect => defect.name === 'Raveling' && defect.level === 'High')?.value || 0
        );
        const roadLabels = reportKeys.map(road => road.start);
        return {
            series: roadData,
            options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        },
                    },
                },
                labels: roadLabels,
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'start',
                    fontSize: '8px',
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                                total: {
                                    show: true,
                                    label: 'Total',
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => `${val} defects`
                    }
                },
                colors: autoGeneratedColors
            }
        };
    }, [autoGeneratedColors, reportKeys]);
    const roadHShovingApexConfig = useMemo(() => {
        const roadData = reportKeys.map(road =>
            road.defectDetails.find(defect => defect.name === 'Shoving' && defect.level === 'High')?.value || 0
        );
        const roadLabels = reportKeys.map(road => road.start);
        return {
            series: roadData,
            options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        },
                    },
                },
                labels: roadLabels,
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'start',
                    fontSize: '8px',
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                                total: {
                                    show: true,
                                    label: 'Total',
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => `${val} defects`
                    }
                },
                colors: autoGeneratedColors
            }
        };
    }, [autoGeneratedColors, reportKeys]);
    const roadHPotholeApexConfig = useMemo(() => {
        const roadData = reportKeys.map(road =>
            road.defectDetails.find(defect => defect.name === 'Pothole' && defect.level === 'High')?.value || 0
        );
        const roadLabels = reportKeys.map(road => road.start);
        return {
            series: roadData,
            options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                        },
                    },
                },
                labels: roadLabels,
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'start',
                    fontSize: '8px',
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                                total: {
                                    show: true,
                                    label: 'Total',
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => `${val} defects`
                    }
                },
                colors: autoGeneratedColors
            }
        };
    }, [autoGeneratedColors, reportKeys]);

    const comphighdefsrs = useMemo(() => {
        const settlements = [];
        const ravelings = [];
        const shovings = [];
        const roadLabels = [];

        reportKeys.forEach(road => {
            roadLabels.push(road.start);
            const settlement = road.defectDetails.find(d => d.name === 'Settlement' && d.level === 'High')?.value || 0;
            settlements.push(settlement);
            const raveling = road.defectDetails.find(d => d.name === 'Raveling' && d.level === 'High')?.value || 0;
            ravelings.push(raveling);
            const shoving = road.defectDetails.find(d => d.name === 'Shoving' && d.level === 'High')?.value || 0;
            shovings.push(shoving);
        });
        return { settlements, ravelings, shovings, roadLabels };
    }, [reportKeys]);

    const stackedColumnConfig = {
        series: [{
            name: 'Settlements - High',
            data: comphighdefsrs.settlements
        }, {
            name: 'Raveling - High',
            data: comphighdefsrs.ravelings
        }, {
            name: 'Shoving - High',
            data: comphighdefsrs.shovings
        }],
        options: {
            chart: {
                type: 'bar',
                stacked: true
            },
            colors: ['#962DFF', '#C893FD', '#E0C6FD'],
            xaxis: {
                categories: comphighdefsrs.roadLabels,
                labels: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%'
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                fontSize: '8px',
                offsetY: 8
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " defects";
                    }
                }
            }
        }
    };

    const comphighdefcpr = useMemo(() => {
        const cracks = [];
        const potholes = [];
        const ruts = [];
        const roadLabels = [];

        reportKeys.forEach(road => {
            roadLabels.push(road.start);
            const crackValue = road.defectDetails.find(d => d.name === 'Crack' && d.level === 'High')?.value || 0;
            cracks.push(crackValue);
            const potholeValue = road.defectDetails.find(d => d.name === 'Pothole' && d.level === 'High')?.value || 0;
            potholes.push(potholeValue);
            const rutValue = road.defectDetails.find(d => d.name === 'Rut' && d.level === 'High')?.value || 0;
            ruts.push(rutValue);
        });

        return { cracks, potholes, ruts, roadLabels };
    }, [reportKeys]);

    const stackedColumnConfigcpr = {
        series: [{
            name: 'Crack - High',
            data: comphighdefcpr.cracks
        }, {
            name: 'Pothole - High',
            data: comphighdefcpr.potholes
        }, {
            name: 'Rut - High',
            data: comphighdefcpr.ruts
        }],
        options: {
            chart: {
                type: 'bar',
                stacked: true
            },
            colors: ['#962DFF', '#C893FD', '#E0C6FD'],
            xaxis: {
                categories: comphighdefcpr.roadLabels,
                labels: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%'
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetY: 8
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " defects";
                    }
                }
            }
        }
    };

    const severityR = useMemo(() => {
        const ravelinglow = [];
        const ravelingmedium = [];
        const ravelinghigh = [];
        const roadLabels = [];

        reportKeys.forEach(road => {
            roadLabels.push(road.start);
            const ravelinglowValue = road.defectDetails.find(d => d.name === 'Raveling' && d.level === 'Low')?.value || 0;
            ravelinglow.push(ravelinglowValue);
            const ravelingmediumValue = road.defectDetails.find(d => d.name === 'Raveling' && d.level === 'Medium')?.value || 0;
            ravelingmedium.push(ravelingmediumValue);
            const ravelinghighValue = road.defectDetails.find(d => d.name === 'Raveling' && d.level === 'High')?.value || 0;
            ravelinghigh.push(ravelinghighValue);
        });

        return { ravelinglow, ravelingmedium, ravelinghigh, roadLabels };
    }, [reportKeys]);

    const stackedColumnConfigsevR = {
        series: [{
            name: 'Raveling - Low',
            data: severityR.ravelinglow
        }, {
            name: 'Raveling - Medium',
            data: severityR.ravelingmedium
        }, {
            name: 'Raveling - High',
            data: severityR.ravelinghigh
        }],
        options: {
            chart: {
                type: 'bar',
                stacked: true
            },
            colors: ['#FFAB1E', '#FEEC3C', '#D71E10'],
            xaxis: {
                categories: severityR.roadLabels,
                labels: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%'
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetY: 8
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " defects";
                    }
                }
            }
        }
    };

    const severityP = useMemo(() => {
        const potholelow = [];
        const potholemedium = [];
        const potholehigh = [];
        const roadLabels = [];

        reportKeys.forEach(road => {
            roadLabels.push(road.start);
            const potholelowValue = road.defectDetails.find(d => d.name === 'Pothole' && d.level === 'Low')?.value || 0;
            potholelow.push(potholelowValue);
            const potholemediumValue = road.defectDetails.find(d => d.name === 'Pothole' && d.level === 'Medium')?.value || 0;
            potholemedium.push(potholemediumValue);
            const potholehighValue = road.defectDetails.find(d => d.name === 'Pothole' && d.level === 'High')?.value || 0;
            potholehigh.push(potholehighValue);
        });

        return { potholelow, potholemedium, potholehigh, roadLabels };
    }, [reportKeys]);

    const stackedColumnConfigsevP = {
        series: [{
            name: 'Pothole - Low',
            data: severityP.potholelow
        }, {
            name: 'Pothole - Medium',
            data: severityP.potholemedium
        }, {
            name: 'Pothole - High',
            data: severityP.potholehigh
        }],
        options: {
            chart: {
                type: 'bar',
                stacked: true
            },
            colors: ['#FFAB1E', '#FEEC3C', '#D71E10'],
            xaxis: {
                categories: severityP.roadLabels,
                labels: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%'
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetY: 8
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " defects";
                    }
                }
            }
        }
    };

    const severityC = useMemo(() => {
        const cracklow = [];
        const crackmedium = [];
        const crackhigh = [];
        const roadLabels = [];

        reportKeys.forEach(road => {
            roadLabels.push(road.start);
            const cracklowValue = road.defectDetails.find(d => d.name === 'Crack' && d.level === 'Low')?.value || 0;
            cracklow.push(cracklowValue);
            const crackmediumValue = road.defectDetails.find(d => d.name === 'Crack' && d.level === 'Medium')?.value || 0;
            crackmedium.push(crackmediumValue);
            const crackhighValue = road.defectDetails.find(d => d.name === 'Crack' && d.level === 'High')?.value || 0;
            crackhigh.push(crackhighValue);
        });

        return { cracklow, crackmedium, crackhigh, roadLabels };
    }, [reportKeys]);

    const stackedColumnConfigsevC = {
        series: [{
            name: 'Crack - Low',
            data: severityC.cracklow
        }, {
            name: 'Crack - Medium',
            data: severityC.crackmedium
        }, {
            name: 'Crack - High',
            data: severityC.crackhigh
        }],
        options: {
            chart: {
                type: 'bar',
                stacked: true,
            },
            colors: ['#FFAB1E', '#FEEC3C', '#D71E10'],
            xaxis: {
                categories: severityC.roadLabels,
                labels: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%'
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetY: 8
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " defects";
                    }
                }
            }
        }
    };

    useEffect(() => {
        const fetchReportKeys = async () => {
            try {
                console.log('Fetching report keys...');
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}project/get_report_1_keys`
                );

                if (!response.data || !response.data.result) {
                    console.error('Error fetching report keys:', response.statusText);
                    throw new Error('Error fetching report keys');
                }
                const { result, city: responseCity, created: responsecreated } = response.data;
                //const { result } = response.data;
                console.log('Report keys fetched successfully:', result);
                const { defectTotals, overallCount } = calculateDefectTotals(result);
                const roadLength = calculateTotalRoadLength(result);
                setDefects(defectTotals);
                setOverallDefectCount(overallCount);
                setTotalRoadLength(roadLength);
                setReportKeys(result);
                setCity(responseCity);
                setCreated(responsecreated);
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        };
        const calculateDefectTotals = (reportKeys) => {
            const defectTotals = {};
            let overallCount = 0;

            reportKeys.forEach((report) => {
                report.defectDetails.forEach((detail) => {
                    const key = detail.name;

                    if (!defectTotals[key]) {
                        defectTotals[key] = {
                            name: detail.name,
                            value: detail.value,
                        };
                    } else {
                        defectTotals[key].value += detail.value;
                    }

                    // Increment overall defect count
                    overallCount += detail.value;
                });
            });

            return { defectTotals: Object.values(defectTotals), overallCount };
        };
        const calculateTotalRoadLength = (reportKeys) => {
            return reportKeys.reduce((totalLength, report) => totalLength + report.roadLength, 0);
        };
        fetchReportKeys();
    }, []);

    return (
        <>
            <div className="flex flex-col h-screen overflow-hidden">
                <div className="flex w-full justify-between px-8 items-center h-16 shadow-md fixed top-0 z-40 bg-white">
                    <Link to="/pavement/app" className="logo">
                        <img src={theLogo} alt="logo" className="w-44" />
                    </Link>
                    <div className="center_profile">
                        <p className="text-lg font-semibold text-gray-800">RoadVision AI Road Inspection Report</p>
                    </div>
                    <div className="right_profile">
                        <div className="flex gap-2">
                            <div className="flex items-center">
                                <MdCalendarMonth size="20" />
                            </div>
                            <div className="flex items-center">
                                <p className="text-sm text-gray-800">
                                    {loading ? <Skeleton.Input active size='small' /> :
                                        <p>{created}</p>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header flex flex-col mt-16 overflow-auto p-8">
                    <div className="flex gap-4 w-full items-center max-w-screen-l mx-auto">
                        <div className="dashboard-box w-full md:w-1/3 lg:w-1/3 bg-white p-4 rounded-md shadow-md border border-gray-300 flex items-center justify-between">
                            <div className="flex flex-col">
                                <div className="flex items-center">
                                    <img src={area} alt="Area Icon" className="w-6 h-6 mr-2" />
                                    <p className="text-lg font-semibold text-purple-700">Area</p>
                                </div>
                                <div className="flex items-center">
                                    {loading ? <Skeleton.Input active size='small' /> :
                                        <p>{city}</p>
                                    }
                                </div>
                            </div>
                            <img src={areaui} alt="Area UI Icon" className="w-12 h-12 ml-2" />
                        </div>
                        <div className="dashboard-box w-1/3 bg-white p-4 rounded-md shadow-md border border-gray-300 flex items-center justify-between">
                            <div className="flex flex-col">
                                <div className="flex items-center">
                                    <img src={length} alt="Area Icon" className="w-6 h-6 mr-2" />
                                    <p className="text-lg font-semibold text-green-700">Total Road Length</p>
                                </div>
                                {loading ? <Skeleton.Input active size='small' /> :
                                    <div className="flex items-center">
                                        <p>{totalRoadLength.toFixed(2)} KM ({(totalRoadLength * 1000).toFixed(2)} m)</p>
                                    </div>
                                }
                            </div>
                            <img src={lengthui} alt="Area UI Icon" className="w-15 h-15 ml-2" />
                        </div>
                        <div className="dashboard-box w-1/3 bg-white p-4 rounded-md shadow-md border border-gray-300 flex items-center justify-between">
                            <div className="flex flex-col">
                                <div className="flex items-center">
                                    <img src={defect} alt="Area Icon" className="w-6 h-6 mr-2" />
                                    <p className="text-lg font-semibold text-orange-500">Total Defect Count</p>
                                </div>
                                {loading ? <Skeleton.Input active size='small' /> :
                                    <div className="flex items-center">
                                        <p>{overallDefectCount}</p>
                                    </div>
                                }
                            </div>
                            <img src={defectui} alt="Area UI Icon" className="w-15 h-15 ml-2" />
                        </div>
                    </div>
                    <div className="flex mt-4 gap-4 w-full items-center justify-between">
                        <div className="defect-box w-full flex bg-white p-4 rounded-md shadow-md border border-gray-300 justify-between">
                            <div className="flex flex-col">
                                <p className="text-lg font-semibold text-gray-800">Total Defects Breakdown and Roads Status (According to IRC Rating)</p>
                                {loading ? <Skeleton.Input active size='default' block='block' rows='1' /> :
                                    <div className="flex gap-8 mt-8 justify-center">
                                        {defects.map((defect) => (
                                            <div key={defect.name} className="flex flex-col">
                                                <span className="text-sm text-gray-500">{defect.name}</span>
                                                <span className="font-semibold">{defect.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="border-l h-30 mx-4 border-gray-300"></div>
                            <div className="flex flex-col justify-center">
                                <div className="flex flex-col gap-4">
                                    {Object.entries(roadStatus).map(([level, distance]) => (
                                        <div key={level} className="flex gap-4 items-center">
                                            <span className="text-sm text-gray-500">
                                                {level === '1' ? 'Good' : level === '2' ? 'Fair' : 'Poor'}:
                                            </span>
                                            <span className="font-semibold">{distance.toFixed(2)} KM</span>
                                            <img
                                                src={level === '1' ? level1 : level === '2' ? level2 : level3}
                                                alt={`Level ${level}`}
                                                className="w-40 h-6"
                                            />
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-4 gap-4 w-full items-center">
                        <div className="defect-table-box w-full flex flex-col bg-white p-4 rounded-md shadow-md border border-gray-300">
                            <p className="text-small mb-4 font-semibold text-gray-800">Road Analysis</p>
                            {loading ? <Skeleton active /> :
                                <TableContainer component={Paper}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Start</TableCell>
                                                <TableCell>End</TableCell>
                                                <TableCell>Road Length</TableCell>
                                                <TableCell>Road Rating</TableCell>
                                                <TableCell>Defect</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportKeys.map((row) => (
                                                <Row key={row.start} row={row} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={reportKeys.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            }
                        </div>
                    </div>
                    <div className="flex mt-4 gap-4 w-full items-center">
                        <div className="w-full bg-white p-4 rounded-md shadow-md border border-gray-300">
                            <div className="box w-1/2 h-1/2 float-left border-r border-b border-gray-300">
                                <p className="text-small font-semibold text-gray-800">Proportion of High Settlements</p>
                                {loading ? <Skeleton.Image active /> :
                                    <ReactApexChart
                                        options={roadHSettleApexConfig.options}
                                        series={roadHSettleApexConfig.series}
                                        type="donut"
                                    />
                                }
                            </div>
                            <div className="box w-1/2 h-1/2 float-left border-l border-b border-gray-300">
                                <p className="text-small font-semibold text-gray-800">Proportion of High Raveling</p>
                                {loading ? <Skeleton.Image active /> :
                                    <ReactApexChart
                                        options={roadHRavelingApexConfig.options}
                                        series={roadHRavelingApexConfig.series}
                                        type="donut"
                                    />
                                }
                            </div>
                            <div className="box w-1/2 h-1/2 float-left border-r border-t border-gray-300">
                                <p className="text-small font-semibold text-gray-800">Proportion of High Shoving</p>
                                {loading ? <Skeleton.Image active /> :
                                    <ReactApexChart
                                        options={roadHShovingApexConfig.options}
                                        series={roadHShovingApexConfig.series}
                                        type="donut"
                                    />
                                }
                            </div>
                            <div className="box w-1/2 h-1/2 float-left border-l border-t border-gray-300">
                                <p className="text-small font-semibold text-gray-800">Proportion of High Potholes</p>
                                {loading ? <Skeleton.Image active /> :
                                    <ReactApexChart
                                        options={roadHPotholeApexConfig.options}
                                        series={roadHPotholeApexConfig.series}
                                        type="donut"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-4 gap-4 w-full items-center">
                        <div className="w-full bg-white p-4 rounded-md shadow-md border border-gray-300">
                            <p className="text-small mb-4 font-semibold text-gray-800">Comparison of High Defects</p>
                            <div className="box w-1/2 h-1/2 float-left border-r border-t border-gray-300">
                                <p className="flex justify-center text-small font-semibold text-gray-500">Settlements, Raveling, and Shoving Comparison</p>
                                {loading ? <Skeleton.Node active='active'>
                                    <DotChartOutlined
                                        style={{
                                            fontSize: 40,
                                            color: '#bfbfbf',
                                        }}
                                    />
                                </Skeleton.Node> :
                                    <ReactApexChart
                                        options={stackedColumnConfig.options}
                                        series={stackedColumnConfig.series}
                                        type="bar"
                                    />
                                }
                            </div>
                            <div className="box w-1/2 h-1/2 float-left border-l border-t border-gray-300">
                                <p className="flex justify-center text-small font-semibold text-gray-500">Cracks, Potholes, and Rut Comparison</p>
                                {loading ? <Skeleton.Node active>
                                    <DotChartOutlined
                                        style={{
                                            fontSize: 40,
                                            color: '#bfbfbf',
                                        }}
                                    />
                                </Skeleton.Node> :
                                    <ReactApexChart
                                        options={stackedColumnConfigcpr.options}
                                        series={stackedColumnConfigcpr.series}
                                        type="bar"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-4 gap-4 w-full items-center">
                        <div className="w-full bg-white p-4 rounded-md shadow-md border border-gray-300">
                            <p className="text-small mb-4 font-semibold text-gray-800">Severity Distribution of Defects</p>
                            <div className="box w-1/2 h-1/2 float-left border-r border-t border-gray-300">
                                <p className="flex justify-center text-small font-semibold text-gray-500">Raveling severity distribution</p>
                                {loading ? <Skeleton.Node active>
                                    <DotChartOutlined
                                        style={{
                                            fontSize: 40,
                                            color: '#bfbfbf',
                                        }}
                                    />
                                </Skeleton.Node> :
                                    <ReactApexChart
                                        options={stackedColumnConfigsevR.options}
                                        series={stackedColumnConfigsevR.series}
                                        type="bar"
                                    />
                                }
                            </div>
                            <div className="box w-1/2 h-1/2 float-left border-l border-t border-gray-300">
                                <p className="flex justify-center text-small font-semibold text-gray-500">Potholes severity distribution</p>
                                {loading ? <Skeleton.Node active>
                                    <DotChartOutlined
                                        style={{
                                            fontSize: 40,
                                            color: '#bfbfbf',
                                        }}
                                    />
                                </Skeleton.Node> :
                                    <ReactApexChart
                                        options={stackedColumnConfigsevP.options}
                                        series={stackedColumnConfigsevP.series}
                                        type="bar"
                                    />
                                }
                            </div>
                            <div className="flex justify-center flex-col box h-1/2 w-full float-left border-t border-gray-300">
                                <p className="flex justify-center text-small font-semibold text-gray-500">Cracks severity distribution</p>
                                {loading ? <Skeleton.Node active>
                                    <DotChartOutlined
                                        style={{
                                            fontSize: 40,
                                            color: '#bfbfbf',
                                        }}
                                    />
                                </Skeleton.Node> :
                                    <div className='w-1/2 mx-auto'>
                                        <ReactApexChart
                                            options={stackedColumnConfigsevC.options}
                                            series={stackedColumnConfigsevC.series}
                                            type="bar"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-4 gap-4 w-full items-center">
                        <div className="w-full bg-white p-4 rounded-md shadow-md border border-gray-300">
                            <p className="text-small mb-4 font-semibold text-gray-800">Captured Road Imagery and Mounted Device Photos</p>
                            <Carousel autoplay>
                                <div>
                                    <img src={maps_ss} alt="Road Condition" className="w-full" />
                                </div>
                                <div>
                                    <img src={ds_ss} alt="Road Condition" className="w-full" />
                                </div>
                                {/* <div>
                                    <img src={mount_ss} alt="Road Condition" className="w-full" />
                                </div> */}
                            </Carousel>
                            <p className="flex justify-center text-small mb-4 text-gray-800 mt-4">RoadVision Mobile App capturing road imagery</p>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default FinalReport
