import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Close } from "@mui/icons-material";
import { FormGroup, FormControlLabel, Checkbox, Typography, Radio, FormControl } from "@mui/material";
import Icon from '@mui/material/Icon';
import { GoogleMap, Marker, Polyline, useJsApiLoader, OverlayView } from "@react-google-maps/api";
import axios from "axios";
import * as _ from "underscore";
import { saveAs } from "file-saver";
import Geocode from "react-geocode";
import moment from "moment-timezone";
import { Segmented } from 'antd';
import GaugeChart from 'react-gauge-chart'
import { IoMapSharp } from "react-icons/io5";
import { PiVideoDuotone } from "react-icons/pi";
import { RiCalculatorFill, RiArticleFill, RiFileDownloadFill, RiHistoryFill, RiNotification3Fill, RiTrafficLightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import LoadingScreen from "../../../utils/LoadingScreen";
import "./MultiMap.css";
//import logo from "../../assets/2.png";
import logo from "../../../assets/RVlogo.svg";
import RVlogo from "../../../assets/Roadvision.png";
//import RVlogo from "../../assets/logoup.png";
import Expand from "../../../assets/Expand.svg";
import { FcPrevious, FcNext, FcVideoCall, FcNoVideo, FcCamera } from "react-icons/fc";
//import mandatory from "../../assets/mandatory.svg";
//import roadasset from "../../assets/road_asset.svg";
import { tr } from "@faker-js/faker";

const MultiMap = () => {
  const [mode, setMode] = useState('Conditions');
  useEffect(() => { }, [mode]);
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEYS);
  const [markers, setMarkers] = useState([]);
  const [open, setOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState("ai_analyzed");
  const [polylinePaths, setPolylinePaths] = useState([]);
  const [allInferenceList, setInferenceList] = useState([]);
  const [info, setInfo] = useState({});
  const [selectedPolylineData, setSelectedPolylineData] = useState(null);
  const [isPolylineModalOpen, setPolylineModalOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState({ latitude: null, longitude: null });
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
  const [isRoad, setIsRoad] = useState(false);
  const [imageSrc, setImageSrc] = useState({
    og_src: "",
    ai_src: "",
  });
  const [backupMarkerState, setBackupMarkerState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarMenuCollapsed, setIsSidebarMenuCollapsed] = useState(true);
  const toggleSidebarMenu = () => {
    setIsSidebarMenuCollapsed(!isSidebarMenuCollapsed);
  };
  var polylineSegments = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { uid_list } = JSON.parse(
    decodeURIComponent(searchParams.get("listOfIds"))
  );
  const imageRef = useRef(null);
  const updatePolylinePaths = (newMarkers) => {
    // Create polyline paths from the markers
    if (isRoad) {
      const paths = newMarkers.map((marker) => ({
        lat: marker.latitude,
        lng: marker.longitude,
      }));
      setPolylinePaths(paths);
    } else {
      setPolylinePaths([]);
    }
  };
  polylineSegments.push(polylinePaths);
  useEffect(() => {
    // Call the function to update the polyline paths whenever markers change
    updatePolylinePaths(markers);
  }, [markers]);
  useEffect(() => {
    let tempMarkers = markers;
    if (isRoad) {
      tempMarkers = _.map(markers, (marker) => {
        return { ...marker, isvisible: false };
      });
      let temp = checkboxes;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = false;
      });
      setCheckboxes({ ...temp });
      temp = severityOptions;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = false;
      });
      setSeverityOptions({ ...temp });
    } else {
      polylineSegments = [];
      tempMarkers = _.map(markers, (marker) => {
        return { ...marker, isvisible: true };
      });
      let temp = segmentOptions;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = false;
      });
      setSegmentOptions({ ...temp });
    }
    setMarkers(tempMarkers);
    filterCheckboxMarkers();
  }, [isRoad]);

  useEffect(() => {
    const fetchData = async () => {
      const projectIds = JSON.parse(decodeURIComponent(searchParams.get("listOfIds")));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}project/get_project_by_id`,
          { project_uids: projectIds },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const inferenceList = response.data.result.inferences;
        setInferenceList(inferenceList);

        let markers = [];
        inferenceList.forEach((inference) => {
          inference.data.frame_list_data.forEach((point) => {
            point.CODEBUILD_BUILD_ID = inference.data.CODEBUILD_BUILD_ID;
            point.NEW_CODEBUILD_BUILD_ID = inference.data.NEW_CODEBUILD_BUILD_ID;
            point.road_rating = inference.road_rating;
            point.created = inference.created["$date"];
            markers.push(point);
          });
        });
        const markerData = markers;
        function removeDuplicates(markerData) {
          const uniqueEntries = [];
          const uniqueCoordinates = new Set();
          for (const entry of markerData) {
            const { latitude, longitude, inference_info } = entry;
            const coordinate = `${latitude},${longitude}`;
            entry.isvisible = true;
            if (uniqueCoordinates.has(coordinate)) {
              if (inference_info.length > 0) {
                const index = uniqueEntries.findIndex(
                  (e) => e.latitude === latitude && e.longitude === longitude
                );
                uniqueEntries[index] = entry;
              }
            } else {
              uniqueCoordinates.add(coordinate);
              uniqueEntries.push(entry);
            }
          }
          setMarkers(uniqueEntries);
          setBackupMarkerState(uniqueEntries);
        }
        removeDuplicates(markerData);
      } catch (err) {
        console.log("ERROR FROM BATCH REQUEST ==>>>", err);
        return err;
      }
    };
    fetchData();
  }, [uid_list, searchParams]);

  const handleDownload = () => {
    const image = imageRef.current;
    saveAs(image.src, "image");
  };
  const selectedImageSrc = useMemo(() => {
    return selectedRadio === "original" ? imageSrc.og_src : imageSrc.ai_src;
  }, [selectedRadio, imageSrc]);
  const handleClose = () => {
    setOpen(false);
  };
  const getPathFromInference = (inference) => {
    if (!isRoad) {
      return [];
    }
    let keyArray = [];
    Object.values(segmentOptions).map((checkbox, index) => {
      if (checkbox["selected"]) {
        keyArray.push(index);
      }
    });
    let tempRoadRatingLevel = 0;
    let color
    const roadRating = inference["data"]["road_rating"];
    if (roadRating <= 40) {
      tempRoadRatingLevel = 1;
      color = "#FF0000";
    } else if (roadRating >= 40 && roadRating <= 80) {
      tempRoadRatingLevel = 2;
      color = "#FFFF00";
    } else if (roadRating > 80) {
      tempRoadRatingLevel = 3;
      color = "#00FF00";
    }
    if (keyArray.length > 0) {
      if (keyArray.includes(tempRoadRatingLevel)) {
        let currPath = [];
        for (const entry of inference["data"]["frame_list_data"]) {
          currPath.push({ lat: entry.latitude, lng: entry.longitude });
        }
        return currPath;
      } else {
        return [];
      }
    } else {
      let currPath = [];
      for (const entry of inference["data"]["frame_list_data"]) {
        currPath.push({ lat: entry.latitude, lng: entry.longitude });
      }
      return currPath;
    }
  };

  const handleMarkerClick = useCallback(async (
    og_file,
    ai_file,
    defectState,
    maininfo,
    latitude,
    longitude,
    codebuidlid,
    created_date,
    defect_state,
    markerId,
    markerIndex
  ) => {
    setLoading(true);
    try {
      const [response1, response2] = await Promise.all([
        axios.post(
          `${process.env.REACT_APP_BASE_URL}project/get_signed_url`,
          { s3Key: og_file, CODEBUILD_BUILD_ID: codebuidlid },
        ),
        axios.post(
          `${process.env.REACT_APP_BASE_URL}project/get_signed_url`,
          { s3Key: ai_file, CODEBUILD_BUILD_ID: codebuidlid },
        ),
      ]);

      setImageSrc({ og_src: response1.data.result.url, ai_src: response2.data.result.url });
      setOpen(true);

      const uniqueLabels = [...new Set(maininfo.map(info => info.label))].join(",");
      const address = await getAddressFromGoogleMaps(latitude, longitude);
      const date = moment(created_date);

      setInfo({
        defectState: defectState,
        label: uniqueLabels,
        latitude,
        longitude,
        address,
        defect_state,
        date_time: date.tz("Asia/Kolkata").format("MMMM Do, YYYY h:mma"),
      });

      setSelectedMarker({ latitude, longitude });
      setCurrentMarkerIndex(markerIndex);
    } catch (err) {
      console.error("Error fetching images:", err);
    }
    setLoading(false);
  }, []);
  const getAddressFromGoogleMaps = async (lat, long) => {
    let address = await Geocode.fromLatLng(lat, long);
    return address.results[0].formatted_address;
  };
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };
  const handlePolylineClick = async (event, inference) => {
    console.log("Polyline clicked!", inference);
    const {
      data: {
        road_length,
        frame_list_data,
        road_rating,
        data_submitted
      }
    } = inference;
    const roadRating = typeof road_rating === "number" ? road_rating : 0;
    const roadLength = typeof road_length === "number" ? road_length : 0;
    if (!frame_list_data || frame_list_data.length === 0) {
      console.error("frame_list_data is empty or missing");
      return;
    }
    const entryPoint = {
      lat: frame_list_data[0].latitude,
      lng: frame_list_data[0].longitude
    };
    const exitPoint = {
      lat: frame_list_data[frame_list_data.length - 1].latitude,
      lng: frame_list_data[frame_list_data.length - 1].longitude
    };
    let totalDistance = 0;
    for (let i = 1; i < frame_list_data.length; i++) {
      const prevFrame = frame_list_data[i - 1];
      const currentFrame = frame_list_data[i];
      // Skip frames with the same latitude and longitude
      if (
        prevFrame.latitude === currentFrame.latitude &&
        prevFrame.longitude === currentFrame.longitude
      ) {
        continue;
      }
      // Calculate distance between previous frame and current frame
      const distance = calculateDistance(
        prevFrame.latitude,
        prevFrame.longitude,
        currentFrame.latitude,
        currentFrame.longitude
      );
      totalDistance += distance;
    }
    console.log("Total distance:", totalDistance, "km")
    const startingAddress = await getAddressFromGoogleMaps(entryPoint.lat, entryPoint.lng);
    const endingAddress = await getAddressFromGoogleMaps(exitPoint.lat, exitPoint.lng);
    const roadCondition = roadRating <= 40 ? "Poor" : (roadRating <= 80 ? "Fair" : "Good");
    const defects = [...new Set(frame_list_data.map(item => item.defect))];
    const polylineData = {
      roadRating: roadRating,
      roadLength: roadLength,
      roadCondition: roadCondition,
      dateSubmitted: data_submitted,
      entryPoint: entryPoint,
      exitPoint: exitPoint,
      startingAddress: startingAddress,
      endingAddress: endingAddress,
      defects: defects
    };
    setSelectedPolylineData(polylineData);
    setPolylineModalOpen(true);
  };
  const getRoadColor = (roadRating) => {
    if (roadRating <= 40) {
      return "#FF0000";
    } else if (roadRating > 40 && roadRating <= 80) {
      return "#FFFF00";
    } else if (roadRating > 80) {
      return "#00FF00";
    }
  };
  const getMarkerColor = (defectState, isActive) => {
    if (isActive) {
      return "cyan";
    }
    switch (defectState) {
      case "low":
        return "#FFFF00";
      case "medium":
        return "#FF9900";
      case "high":
        return "#FF0000";
      default:
        return "#00ff00";
    }
  };
  const getSevereState = (severity) => {
    if (severity === 0) {
      return "None";
    } else if (severity === 1) {
      return "Mild";
    } else if (severity >= 2 && severity <= 3) {
      return "Moderate";
    } else {
      return "Severe";
    }
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEYS,
  });
  const sumLat = markers.reduce((acc, point) => acc + point.latitude, 0);
  const sumLng = markers.reduce((acc, point) => acc + point.longitude, 0);
  const avgLat = useMemo(() => markers.length > 0 ? sumLat / markers.length : 0, [markers, sumLat]);
  const avgLng = useMemo(() => markers.length > 0 ? sumLng / markers.length : 0, [markers, sumLng]);

  useEffect(() => {
    setCenter({ lat: avgLat, lng: avgLng });
  }, [avgLat, avgLng]);

  // Update center based on the selected marker
  useEffect(() => {
    if (selectedMarker.latitude !== null && selectedMarker.longitude !== null) {
      setCenter({ lat: selectedMarker.latitude, lng: selectedMarker.longitude });
    }
  }, [selectedMarker]);
  const mapId = "c7ce1a914d921fb";
  const defaultCustomMarker = {
    path: "M5,0A5,5 0 1,0 15,0A5,5 0 1,0 5,0",
    fillColor: "red",
    fillOpacity: 3,
    strokeWeight: 1,
    rotation: 1,
    scale: 0.6,
  };
  const selectedCustomMarker = {
    path: "M5,0A5,5 0 1,0 15,0A5,5 0 1,0 5,0",
    fillColor: "cyan",
    fillOpacity: 3,
    strokeWeight: 1,
    rotation: 1,
    scale: 0.6,
  };
  const calculateScale = (zoom) => {
    const baseScale = 0.8;
    const maxZoom = 18;
    let scale = baseScale + (zoom - 15) * 0.2;
    scale = Math.min(scale, baseScale + (maxZoom - 15) * 10);
    return scale;
  };
  function handleZoomChanged() {
    const newZoomLevel = this.getZoom();
    setZoomLevel(newZoomLevel);
  }
  // // Filter By Road Segments State
  const [segmentOptions, setSegmentOptions] = useState({
    0: { label: "All", selected: false },
    1: { label: "Poor", color: "#FF0000", selected: false },
    2: { label: "Fair", color: "#FFA500", selected: false },
    3: { label: "Good", color: "#00FF00", selected: false },
  });
  const handleSegmentOptionState = (id) => {
    let temp = { ...segmentOptions };
    // If "All" is selected, toggle all options
    if (id === 0) {
      const allSelected = !temp[0].selected;
      Object.keys(temp).forEach((key) => {
        temp[key].selected = allSelected;
      });
    } else {
      // If any other option is selected, toggle its selected state
      temp[id].selected = !temp[id].selected;
      // If all other options are selected, unselect "All"
      if (Object.values(temp).slice(1).every((option) => option.selected)) {
        temp[0].selected = true;
      } else {
        temp[0].selected = false;
      }
    }
    setSegmentOptions(temp);
  };
  useEffect(() => {
    let keyArray = Object.values(segmentOptions)
      .slice(1)
      .filter((checkbox) => checkbox.selected)
      .map((checkbox) => checkbox.label.toLowerCase());
    if (keyArray.length !== 0) {
      setIsRoad(true);
      setAllFalse("checkboxes");
      setAllFalse("severity");
    } else {
      setIsRoad(false);
    }
  }, [segmentOptions]);
  // Filter by Points State
  const [checkboxes, setCheckboxes] = useState({
    0: { id: "0", label: "All Defect Types", prefix: "ALL", selected: false },
    1: { id: "1", label: "Cracks", prefix: "C", code: "CRACKS", selected: false },
    2: { id: "2", label: "Patching", prefix: "P", code: "PATCHING", selected: false },
    3: { id: "3", label: "Potholes", prefix: "POT", code: "POTHOLES", selected: false },
    4: { id: "4", label: "Ravelling", prefix: "RAV", code: "RAVELLING", selected: false },
    5: { id: "5", label: "Rut", prefix: "RUT", code: "RUT", selected: false },
    6: { id: "6", label: "Settlements", prefix: "SET", code: "SET", selected: false },
    7: { id: "7", label: "Shoving", prefix: "SH", code: "SHOVING", selected: false },
  });
  // Handling Checkbox Change
  const handleCheckboxChange = (event, id) => {
    setIsRoad(false);
    if (id === 0 && event.target.checked) {
      setAllTrue("checkboxes");
    } else if (id === 0 && !event.target.checked) {
      setAllFalse("checkboxes");
    }
    if (id !== 0) {
      let temp = checkboxes;
      temp[id]["selected"] = !temp[id]["selected"];
      setCheckboxes({ ...temp });
    }
    filterCheckboxMarkers();
  };
  // Checkboxes State Functions
  const setAllTrue = (setKey) => {
    let temp = {};
    if (setKey === "checkboxes") {
      temp = checkboxes;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = true;
      });
      setCheckboxes({ ...temp });
    }
    if (setKey === "severity") {
      temp = severityOptions;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = true;
      });
      setSeverityOptions({ ...temp });
    }
    filterCheckboxMarkers();
  };
  // Checkboxes State Functions
  const setAllFalse = (setKey) => {
    let temp = {};
    if (setKey === "checkboxes") {
      temp = checkboxes;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = false;
      });
      setCheckboxes({ ...temp });
    }
    if (setKey === "severity") {
      temp = severityOptions;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = false;
      });
      setSeverityOptions({ ...temp });
    }
    filterCheckboxMarkers();
  };
  // Checkboxes State Functions
  const checkIfAllPresent = (number, array) => {
    return (
      JSON.stringify(Array.from({ length: number - 1 }, (_, i) => i + 1)) ===
      JSON.stringify(array.sort())
    );
  };
  // Checkboxes State Functions
  const filterCheckboxMarkers = () => {
    let tempMarkers = [];
    let pointKeyArray = [];
    let pointIndexArray = [];

    Object.values(checkboxes).map((checkbox, index) => {
      if (checkbox["selected"]) {
        pointKeyArray.push(checkbox["label"].toLowerCase());
        pointIndexArray.push(index);
      }
    });
    if (checkIfAllPresent(Object.keys(checkboxes).length, pointIndexArray)) {
      let temp = checkboxes;
      temp[0]["selected"] = true;
      setCheckboxes({ ...temp });
    } else {
      let temp = checkboxes;
      temp[0]["selected"] = false;
      setCheckboxes({ ...temp });
    }
    let keyArray = [];
    let keyIndex = [];
    Object.values(severityOptions).map((checkbox, index) => {
      if (checkbox["selected"]) {
        keyArray.push(checkbox["label"].toLowerCase());
        keyIndex.push(index);
      }
    });
    if (checkIfAllPresent(Object.keys(severityOptions).length, keyIndex)) {
      let temp = severityOptions;
      temp[0]["selected"] = true;
      setSeverityOptions({ ...temp });
    } else {
      let temp = severityOptions;
      temp[0]["selected"] = false;
      setSeverityOptions({ ...temp });
    }

    let roadAssetKeyArray = [];
    Object.values(roadsegmentOptions).map((checkbox, index) => {
      if (checkbox["selected"]) {
        roadAssetKeyArray.push(checkbox["label"].toLowerCase());
      }
    });

    tempMarkers = _.map(markers, function (item) {
      let temp_num = _.filter(item.inference_info, function (info) {
        if (keyArray.length !== 0 && pointIndexArray.length !== 0) {
          return (
            pointKeyArray.includes(info.label) &&
            keyArray.includes(info.severity)
          );
        } else if (keyArray.length > 0 && pointIndexArray.length === 0) {
          // Check the defect_state of the marker against the selected severity options
          return keyArray.includes(item.defect_state.toLowerCase());
        } else if (keyArray.length === 0 && pointIndexArray.length > 0) {
          return pointKeyArray.includes(info.label);
        } else {
          return true;
        }
      }).length;

      let asset_num = _.filter(item.asset_info, function (info) {
        return roadAssetKeyArray.includes(info.label.toLowerCase());
      }).length;

      //   return temp_num
      //     ? { ...item, isvisible: true }
      //     : { ...item, isvisible: false };
      // });
      return (temp_num && (roadAssetKeyArray.length === 0 || asset_num))
        ? { ...item, isvisible: true }
        : { ...item, isvisible: false };
    });

    // if (pointKeyArray.length === 0 && keyArray.length === 0 && !isRoad) {
    //   setMarkers(backupMarkerState);
    // } else if (!isRoad) {
    //   setMarkers(tempMarkers);
    // }
    if (pointKeyArray.length === 0 && keyArray.length === 0 && roadAssetKeyArray.length === 0 && !isRoad) {
      setMarkers(backupMarkerState);
    } else if (!isRoad) {
      setMarkers(tempMarkers);
    }
  };
  // // Severity options state
  const [severityOptions, setSeverityOptions] = useState({
    0: { label: "Select All", color: "#1967D2", selected: false },
    1: { label: "Low", color: "#E1E105", checkedColor: "#E1E105", selected: false }, // Green for Low
    2: { label: "Medium", color: "#FFA500", checkedColor: "#FFA500", selected: false }, // Yellow for Medium
    3: { label: "High", color: "#9E0042", checkedColor: "#FF0000", selected: false }, // Red for High
  });
  // Severity State functions
  const handleEnroachChange = (event, index) => {
    setIsRoad(false);
    let temp = segmentOptions;
    Object.keys(temp).forEach((key) => {
      temp[key]["selected"] = false;
    });
    setSegmentOptions({ ...temp });
    if (index === 0 && event.target.checked) {
      setAllTrue("severity");
    } else if (index === 0 && !event.target.checked) {
      setAllFalse("severity");
    }
    if (index !== 0) {
      let temp = severityOptions;
      temp[index]["selected"] = !temp[index]["selected"];
      setSeverityOptions({ ...temp });
    }
    filterCheckboxMarkers();
  };

  // const [roadsegmentOptions, setRoadSegmentOptions] = useState({
  //   // 0: { label: "All Signs", selected: false },
  //   // 1: { label: "Mandatory Signs", color: "#FF0000", selected: false },
  //   // 2: { label: "Warning Signs", color: "#FFA500", selected: false },
  //   // 3: { label: "Informatory Signs", color: "#00FF00", selected: false },
  //   0: { label: "Road Asset", color: "#0000FF", selected: false },
  // });
  const [roadsegmentOptions, setRoadSegmentOptions] = useState({
    0: { id: "0", label: "All Asset Types", prefix: "ALL", selected: false },
    1: { id: "1", label: "Advertisement board", prefix: "Ad", selected: false },
    2: { id: "2", label: "Bollards", prefix: "Bo", selected: false },
    3: { id: "3", label: "Drainage", prefix: "Dr", selected: false },
    4: { id: "4", label: "Fencing", prefix: "Fe", selected: false },
    5: { id: "5", label: "Garbage", prefix: "Ga", selected: false },
    6: { id: "6", label: "Gaurd post", prefix: "Gp", selected: false },
    7: { id: "7", label: "Information board", prefix: "In", selected: false },
    8: { id: "8", label: "Road markings", prefix: "Ro", selected: false },
    9: { id: "9", label: "Road signboard", prefix: "Rs", selected: false },
    10: { id: "10", label: "Street lights", prefix: "Sl", selected: false },
    11: { id: "11", label: "Street vendors", prefix: "Sv", selected: false },
    12: { id: "12", label: "Traffic lights", prefix: "Tl", selected: false },
    13: { id: "13", label: "Zebra crossing", prefix: "Ze", selected: false },
  });
  // const handleFeatureCheckboxChange = (id) => (event) => {
  //   let temp = { ...roadsegmentOptions };
  //   if (id === 0) {
  //     temp[id]["selected"] = event.target.checked;
  //     Object.keys(temp).forEach((key) => {
  //       temp[key]["selected"] = event.target.checked;
  //     });
  //   } else {
  //     temp[id]["selected"] = event.target.checked;
  //     if (Object.values(temp).slice(1).every((option) => option.selected)) {
  //       temp[0].selected = true;
  //     } else {
  //       temp[0].selected = false;
  //     }
  //   }
  //   setRoadSegmentOptions(temp);
  // };
  const handleFeatureCheckboxChange = (index) => (event) => {
    let temp = { ...roadsegmentOptions };
    if (index === 0) {
      temp[index]["selected"] = event.target.checked;
      Object.keys(temp).forEach((key) => {
        temp[key]["selected"] = event.target.checked;
      });
    } else {
      temp[index]["selected"] = event.target.checked;
      if (Object.values(temp).slice(1).every((option) => option.selected)) {
        temp[0].selected = true;
      } else {
        temp[0].selected = false;
      }
    }
    setRoadSegmentOptions(temp);
    filterCheckboxMarkers(); // Update markers after changing the filter
  };


  function containsMultipleIds(listOfIds) {
    try {
      const ids = JSON.parse(listOfIds);
      return Array.isArray(ids) && ids.length > 1;
    } catch (error) {
      return false;
    }
  }
  const listOfIds = searchParams.get("listOfIds");
  const menus = [
    {
      name: "Map",
      link:
        `${window.location.origin.toString()}/pavement/multiMap?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: IoMapSharp,
    },
    {
      name: "Report",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/report?listOfIds=${listOfIds}`,
      icon: RiArticleFill,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Video Inspection",
      link: containsMultipleIds(listOfIds)
        ? "#"
        : `${window.location.origin.toString()}/pavement/video?listOfIds=${listOfIds}`,
      icon: PiVideoDuotone,
      onClick: containsMultipleIds(listOfIds)
        ? (event) => {
          event.preventDefault();
          alert("Please select only one project.");
        }
        : undefined,
    },
    {
      name: "Notifications",
      link:
        `${window.location.origin.toString()}/pavement/notifications?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiNotification3Fill,
    },
    {
      name: "VersionControl",
      link: "#",
      icon: RiHistoryFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "Budget Calculator",
      link:
        `${window.location.origin.toString()}/pavement/calculator?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiCalculatorFill,
    },
    {
      name: "Download Report",
      link: "#",
      icon: RiFileDownloadFill,
      onClick: (event) => {
        event.preventDefault();
        alert("Only Available For Enterprise Customers!");
      },
    },
    {
      name: "Traffic Management System",
      link:
        `${window.location.origin.toString()}/pavement/traffic?listOfIds=` +
        searchParams.get("listOfIds"),
      icon: RiTrafficLightLine,
    },
  ];
  // Custom Map Styles and custom map options
  const mapStyles = [
    {
      elementType: "geometry",
      stylers: [{ color: "#000000" }]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }]
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#808080" }]
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.park",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#404040" }]
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "on", color: "#f9f9f9" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#404040" }]
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [{ visibility: "on", color: "#f9f9f9" }]
    }
  ];
  const RoadRatingGauge = ({ rating }) => {
    return (
      <GaugeChart
        id="gauge-chart1"
        nrOfLevels={10}
        needleColor="#000000"
        arcPadding={0.02}
        textColor="#000000"
        percent={rating / 100}
        colors={['red', 'orange', 'green']}
        style={{ margin: 'auto', width: '50%', height: '50%' }}
      />
    );
  }
  const fieldContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: `${100}%`,
    fontSize: '0.7rem',
  };
  const subFieldContainerStyle = {
    width: "45%",
  };
  const Container = ({ label, value }) => (
    <div style={fieldContainerStyle}>
      <div className="bg-white">
        <div>{label}</div>
        <div>{value}</div>
      </div>
    </div>
  );
  const SubContainer = ({ label, value }) => (
    <div style={{ ...fieldContainerStyle, ...subFieldContainerStyle }}>
      <div>
        <div>{label}</div>
        <div>{value}</div>
      </div>
    </div>
  );
  const goToPreviousMarker = () => {
    setCurrentMarkerIndex(prevIndex => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : markers.length - 1;
      triggerMarkerClick(newIndex);
      return newIndex;
    });
  };
  const goToNextMarker = () => {
    setCurrentMarkerIndex(prevIndex => {
      const newIndex = prevIndex < markers.length - 1 ? prevIndex + 1 : 0;
      triggerMarkerClick(newIndex);
      return newIndex;
    });
  };
  const triggerMarkerClick = (index) => {
    const marker = markers[index];
    if (marker) {
      handleMarkerClick(
        marker.og_file,
        marker.inference_image,
        marker.inference_info.length,
        marker.inference_info,
        marker.latitude,
        marker.longitude,
        marker.NEW_CODEBUILD_BUILD_ID,
        marker.created,
        marker.markerId,
        marker.markerIndex,
        index
      );
    }
  };
  const toggleAutoPlay = () => {
    if (isAutoPlay) {
      clearInterval(autoPlayIntervalId);
    } else {
      startAutoPlay();
    }
    setIsAutoPlay(prev => !prev);
  };
  const startAutoPlay = () => {
    const intervalId = setInterval(() => {
      goToNextMarker();
    }, 3000);
    setAutoPlayIntervalId(intervalId);
  };
  const playPause = isAutoPlay ? (
    <FcNoVideo onClick={toggleAutoPlay} />
  ) : (
    <FcVideoCall onClick={toggleAutoPlay} />
  );

  // const renderInfoIconOverlay = (position, hasAssetInfo) => {
  //   const overlayStyle = {
  //     background: `url(${roadasset}) no-repeat center / contain`,
  //     width: '9px',
  //     height: '9px',
  //     position: 'absolute',
  //     transform: 'translate(50%, -50%)',
  //   };

  //   const roadAssetSelected = roadsegmentOptions[0]?.selected;

  //   if (roadAssetSelected && hasAssetInfo) {
  //     return (
  //       <OverlayView
  //         position={position}
  //         mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //       >
  //         <div style={overlayStyle} />
  //       </OverlayView>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <div className="relative">
      <div className="sidebar-wrapper flex relative">
        <button className={`absolute left-0 ml-4 z-50 ${isSidebarMenuCollapsed ? 'rotate-180' : ''}`} style={{
          marginTop: '23%',
          marginLeft: 'calc(var(--sidebar-width) - 1rem)',
          borderRadius: '20px',
          transition: 'transform 0.3s ease',
        }} onClick={toggleSidebarMenu}>
          <img src={Expand} alt="Collapse-Expand" />
        </button>
        <div className={`sidebar flex fixed z-40 top-0 bottom-0 drop-shadow-2xl ${isSidebarMenuCollapsed ? 'w-[5%]' : 'w-[22%]'} overflow-y-auto overflow-x-hidden bg-white h-full transition-all duration-100 ease-in-out`}>
          <style>
            {`:root {
        --sidebar-width: ${isSidebarMenuCollapsed ? '5%' : '22%'};
      }`}
          </style>
          <div className="bg-[#F5FAFF] h-full w-18 fixed z-40 top-0 bottom-0 text-[#425466] px-4">
            <div className="mt-4  flex  flex-col gap-4 relative">
              <Link to="/pavement/app" className="mb-2">
                <img src={logo} alt="Logo" className="w-10 h-10 cursor-pointer" />
              </Link>
              {menus?.map((menu, i) => {
                return (
                  <Link
                    to={menu.link}
                    key={i}
                    className={` ${menu?.margin && "mt-5"
                      } group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
                    title={menu.name}
                    onClick={menu.onClick}
                  >
                    <div>{React.createElement(menu?.icon, { size: "21" })}</div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className={`sidebar-menu overflow-y-auto overflow-x-hidden`} style={{ marginLeft: '3rem', visibility: isSidebarMenuCollapsed ? 'hidden' : 'visible', maxHeight: isSidebarMenuCollapsed ? '0px' : '1000px', overflowX: 'hidden', }}>
            <div className="p-2.5 pb-6 pl-8 mt-2 flex justify-between items-start w-[220px] ">
              <img src={RVlogo} alt="Roadvision Logo" />
            </div>
            <div className="my-2 bg-[#DADCE0] h-[1px]"></div>
            <div className="mt-0 pl-8">
              <Segmented
                options={['Conditions', 'Features']}
                block
                onChange={(selectedMode) => {
                  setMode(selectedMode);
                }}
              />
            </div>
            {mode === 'Conditions' && (
              <div>
                <div className="mt-4 pl-8">
                  <span className="text-[#1967D2] ml-4">FILTER BY SEGMENTS</span>
                  <FormGroup className="mt-3">
                    {Object.values(segmentOptions).map((checkbox, index) => (
                      <div
                        key={index}
                        className={`flex items-center pl-4  ${segmentOptions[index]["selected"]
                          ? "bg-[#" + checkbox.color + "]"
                          : "bg-white"
                          } rounded`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={segmentOptions[index]["selected"] || false}
                              onChange={(e) => {
                                handleSegmentOptionState(index);
                              }}
                              style={{ color: `${checkbox.color}` }}
                              value={index}
                            />
                          }
                          label={
                            <React.Fragment>
                              <span className="mr-2 font-bold text-[#1967D2]">
                                {checkbox.prefix}
                              </span>
                              {checkbox.label}
                            </React.Fragment>
                          }
                        />
                      </div>
                    ))}
                  </FormGroup>
                </div>

                <div className="my-2 bg-[#DADCE0] h-[1px]"></div>
                <div className="mt-4 pl-8">
                  <span className="text-[#1967D2] ml-4">FILTER BY DEFECT TYPE</span>
                  <FormGroup className="mt-3">
                    {Object.values(checkboxes).map((checkbox, index) => (
                      <div
                        key={checkbox.id}
                        className={`flex items-center pl-4  ${checkboxes[index]["selected"] ? "bg-[#E9F2FF]" : "bg-white"
                          } rounded`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxes[index]["selected"]}
                              onChange={(event) => handleCheckboxChange(event, index)}
                              value={index}
                            />
                          }
                          label={checkbox.label}
                        />
                      </div>
                    ))}
                  </FormGroup>
                </div>
                <div className="my-2 bg-[#DADCE0] h-[1px]"></div>
                <div className="pl-8 mt-4 ">
                  <span className="text-[#1967D2] ml-4">DEFECT STATE</span>
                  <FormGroup className="flex mt-3 ml-4 w-full">
                    {Object.values(severityOptions).map(({ label, color }, index) => (
                      <FormControlLabel
                        key={label}
                        control={
                          <Checkbox
                            checked={severityOptions[index]["selected"]}
                            onChange={(event) => handleEnroachChange(event, index)}
                            style={{ color: `${severityOptions[index]["checkedColor"]}` }}
                            value={index}
                            className="flex "
                          />
                        }
                        label={label}
                        className="flex"
                      />
                    ))}
                  </FormGroup>
                </div>
              </div>
            )}
            {mode === 'Features' && (
              <div>
                <div className="mt-4 pl-8">
                  <span className="text-[#1967D2] ml-2">Road Features</span>
                  <FormGroup className="mt-3">
                    {Object.values(roadsegmentOptions).map((checkbox, index) => (
                      <div
                        key={index}
                        className={`flex items-center pl-2  ${roadsegmentOptions[index]["selected"]
                          ? "bg-[#" + checkbox.color + "]"
                          : "bg-white"
                          } rounded`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={roadsegmentOptions[index]["selected"]}
                              onChange={handleFeatureCheckboxChange(index)}
                              style={{ color: `${checkbox.color}` }}
                              value={index}
                            />

                          }
                          label={
                            <div className="flex justify-between items-center w-full ml-2">
                              <React.Fragment>
                                {checkbox.label}
                              </React.Fragment>
                              {/* <img src={roadasset} alt="ROAD-ASSET" /> */}
                            </div>
                          }
                        />
                      </div>
                    ))}
                  </FormGroup>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full h-screen z-100 ">
        {!isLoaded ? (
          <div className="ml-24">
            <LoadingScreen />
          </div>
        ) : (
          <GoogleMap
            mapContainerStyle={{ height: "100%", width: "100%", position: "relative", left: isPolylineModalOpen ? "-375px" : open ? "-375px" : "0px", }}
            center={center}
            zoom={13}
            mapId={mapId}
            onZoomChanged={handleZoomChanged}
            options={{
              styles: mapStyles,
              disableDefaultUI: true,
              minZoom: 10,
              zoomControl: true,
              fullscreenControl: false,
              streetViewControl: true,
              mapTypeControl: false,
              scaleControl: true,
              rotateControl: true,
              clickableIcons: true,
            }}
          >
            {/* {markers.map((marker, i) => {
              const isSelected = currentMarkerIndex === i;
              const markerIcon = isSelected ? selectedCustomMarker : defaultCustomMarker;
              if (marker.isvisible) {
                return (
                  <Marker
                    key={i}
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                    icon={{
                      ...markerIcon,
                      fillColor: isSelected ? 'cyan' : getMarkerColor(marker.defect_state),
                      scale: calculateScale(zoomLevel),
                    }}
                    onClick={() =>
                      handleMarkerClick(
                        marker.og_file,
                        marker.inference_image,
                        marker.inference_info.length,
                        marker.inference_info,
                        marker.latitude,
                        marker.longitude,
                        marker.NEW_CODEBUILD_BUILD_ID,
                        marker.created,
                        marker.markerId,
                        marker.markerIndex,
                        i,
                      )
                    }
                  />
                );
              }
              return null;
            })} */}
            {
              markers.map((marker, i) => {
                if (marker.isvisible) {
                  const position = { lat: marker.latitude, lng: marker.longitude };
                  const isSelected = currentMarkerIndex === i;
                  const markerIcon = isSelected ? selectedCustomMarker : defaultCustomMarker;
                  const hasAssetInfo = marker.asset_info ? marker.asset_info.length >= 0 : false;
                  return (
                    <React.Fragment key={i}>
                      <Marker
                        position={position}
                        icon={{
                          ...markerIcon,
                          fillColor: isSelected ? 'cyan' : getMarkerColor(marker.defect_state),
                          scale: calculateScale(zoomLevel),
                        }}
                        onClick={() =>
                          handleMarkerClick(
                            marker.og_file,
                            marker.inference_image,
                            marker.inference_info.length,
                            marker.inference_info,
                            marker.latitude,
                            marker.longitude,
                            marker.NEW_CODEBUILD_BUILD_ID,
                            marker.created,
                            marker.markerId,
                            marker.markerIndex,
                            i,
                          )
                        }
                      />
                      {/* {renderInfoIconOverlay(position, hasAssetInfo)} */}
                      {/* {renderInfoIconOverlay(position)} */}
                    </React.Fragment>
                  );
                }
                return null;
              })
            }
            {allInferenceList.map((inference, index) => (
              <Polyline
                key={index}
                path={getPathFromInference(inference)}
                onClick={(event) => handlePolylineClick(event, inference)}
                options={{
                  strokeColor: getRoadColor(inference.data.road_rating),
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  icons: [
                    {
                      icon: "hello",
                      offset: "0",
                      repeat: "10px",
                    },
                  ],
                }}
              />
            ))}
          </GoogleMap>
        )}
      </div>
      {open && (
        <div className="absolute top-0 right-0 w-96 h-full bg-white border border-gray-300 p-4 z-50">
          <div className="flex justify-start bg-white relative w-full gap-12 z-10">
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <Close />
            </div>
            <div className="">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Point Information
              </Typography>
            </div>
          </div>
          {loading ? (
            <div className="mt-32 ml-32 w-full">
              <LoadingScreen />
            </div>
          ) : (
            <div>
              <img
                ref={imageRef}
                src={selectedImageSrc}
                alt=""
                style={{ height: `${100}%`, width: `${100}%` }}
                onError={(e) => (e.target.src = "/assests/404image.svg")}
                onLoad={() => console.log("onload console")}
              />
            </div>
          )}
          <div className="flex flex-col justify-start py-4">
            <FormControl>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value="original"
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                    },
                  }} />}
                  label={<span style={{ fontSize: '0.8rem' }}>Original Photo</span>}
                  labelPlacement="end"
                  checked={selectedRadio === "original"}
                  onChange={() => setSelectedRadio("original")}
                />
                <FormControlLabel
                  value="ai_analyzed"
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                    },
                  }} />}
                  label={<span style={{ fontSize: '0.8rem' }}>AI-Analyzed Photo</span>}
                  labelPlacement="end"
                  checked={selectedRadio === "ai_analyzed"}
                  onChange={() => setSelectedRadio("ai_analyzed")}
                />
              </div>
            </FormControl>
          </div>
          <div className="flex justify-between py-2 bg-gray-300">
            <button className="flex items-center cursor-pointer ml-1 p-2 rounded bg-white hover:bg-gray-100 transition duration-300" onClick={() => goToPreviousMarker()}>
              <FcPrevious />
            </button>
            <button className="flex cursor-pointer mr-20 p-2 rounded bg-white hover:bg-gray-100 transition duration-300" onClick={() => goToNextMarker()}>
              <FcNext />
            </button>
            <button className="flex cursor-pointer p-2 rounded bg-white hover:bg-gray-100 transition duration-300">
              {playPause}
            </button>
            <button className="flex cursor-pointer mr-1 p-2 rounded bg-white hover:bg-gray-100 transition duration-300">
              <FcCamera onClick={handleDownload} />
            </button>
          </div>

          <hr className="border border-gray-300" />
          <div>
            <Container label="Defect Type" value={info.label} />
            {/* <SubContainer label="Defect Severity" value={info.defectState} /> */}
            {/* <SubContainer label="Affected Area" value="3.5 m × 2 m" /> */}
            <hr className="border border-gray-300 my-2" />
            <Container label="Last Updated" value={info.date_time} />
            <hr className="border border-gray-300 my-2" />
            <Container label="Location" value={info.address} />
            <hr className="border border-gray-300 my-2" />
            <SubContainer label="Latitude and Longitude" value={`${info.latitude}, ${info.longitude}`} />
            <hr className="border border-gray-300 my-2" />
            {/* <SubContainer label="Bearing" value="Southeast" /> */}
            <Container label="Feedback" />
            <textarea style={{ resize: 'none', fontSize: 'small' }} rows="4" className="w-full h-20 p-2 border border-gray-300 rounded" />
          </div>

          <hr className="border border-gray-300 my-4" />
        </div>
      )}
      {isPolylineModalOpen ? (
        <div open={isPolylineModalOpen} onClose={() => setPolylineModalOpen(false)}>
          <div className="absolute top-0 right-0 w-96 h-full bg-white border border-gray-300 p-4 z-50">
            {/* Header */}
            <div className="flex justify-start bg-white relative w-full gap-12 z-10">
              <div onClick={() => setPolylineModalOpen(false)} style={{ cursor: "pointer" }}>
                <Close />
              </div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Segment Information
              </Typography>
            </div>
            <hr className="border-black mb-4" />
            <div className="mb-8 text-center p-4 rounded mx-auto"
              style={{
                width: "100%",
                margin: 'auto',
                backgroundColor:
                  selectedPolylineData.roadRating <= 40 ? "red" :
                    selectedPolylineData.roadRating <= 80 ? "orange" : "green"
              }}>
              <div className="flex flex-row items-center justify-center text-base">
                <strong style={{ marginRight: '10px' }}>ROADVISION SCORE:</strong> {selectedPolylineData.roadRating}
              </div>
            </div>
            <div className="mt-4 mb-4 text-center">
              <RoadRatingGauge rating={selectedPolylineData.roadRating} style={{ width: "40%" }} />
            </div>
            {/* Road Condition and Road Length */}
            <div className="flex flex-col gap-4 justify-between mb-4 p-4 rounded bg-white mx-auto">
              <div style={{ flex: 1 }}>
                <div style={{
                  padding: '0.5rem',
                  borderRadius: '0.25rem',
                  color: 'white',
                  backgroundColor: selectedPolylineData.roadCondition === "Poor" ? 'red' :
                    selectedPolylineData.roadCondition === "Fair" ? 'orange' : 'green'
                }}>
                  <strong>Road Condition:</strong> {selectedPolylineData.roadCondition}
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{
                  padding: '0.5rem',
                  borderRadius: '0.25rem',
                  color: 'white',
                  backgroundColor: 'blue'
                }}>
                  <strong>Road Length:</strong> {selectedPolylineData.roadLength} km
                </div>
              </div>
            </div>
            <hr className="border-black mb-4" />
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px', fontSize: '14px', paddingLeft: '1rem', }}>
              <div className="flex-1">
                <strong>Starting Point:</strong> Lat: {selectedPolylineData.entryPoint.lat}, Lng: {selectedPolylineData.entryPoint.lng}
              </div>
              <hr className="border-black" />
              <div className="flex-1">
                <strong>Ending Point:</strong> Lat: {selectedPolylineData.exitPoint.lat}, Lng: {selectedPolylineData.exitPoint.lng}
              </div>
              <hr className="border-black" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px', fontSize: '14px', paddingLeft: '1rem', }}>
              <div className="flex-1">
                <strong>Starting Address:</strong> {selectedPolylineData.startingAddress}
              </div>
              <hr className="border-black" />
              <div className="flex-1">
                <strong>Ending Address:</strong> {selectedPolylineData.endingAddress}
              </div>
            </div>
            {/* Defects */}
            {/* <div className="mb-4" style={{ paddingLeft: '1rem'}}>
          <strong>Defects:</strong>
          <ul className="list-decimal list-inside pl-5 mt-2">
            {selectedPolylineData.defects.map((defect, index) => (
              <li key={index} className="mb-1">{defect}</li>
            ))}
          </ul>
        </div> */}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default MultiMap;
