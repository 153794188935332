import React, { Suspense, Fragment, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingScreen from "./utils/LoadingScreen";
import AuthGuard from "./guard/AuthGuard";
import AdminGuard from "./guard/AdminGuard";
import GuestGuard from "./guard/GuestGuard";
//import MultiMap from "./components/MultiMap/MultiMap";
import Report from "./Pavement/components/reports/Reports";
import VersionControl from "./Pavement/components/VersionControl/VersionControl";
import Analytics from "./Pavement/components/Analytics/Analytics";
import SideBar from "./Pavement/components/sideBar/SideBar";
import Notifications from "./Pavement/components/Notifications/Notifications";
import Calculator from "./Pavement/components/Calculator/Calculator";
import FinalReport from "./Pavement/components/FinalReport/FinalReport";
import Traffic from "./Pavement/components/Traffic/Traffic";
import ViewTask from "./Pavement/components/ViewTask/ViewTask";
import MyTask from "./Pavement/components/MyTask/MyTask";
//import Survey from "./components/Survey/Survey";

const Home = lazy(() => import("./utils/Home/Home"));
const NotFound = lazy(() => import("./authentication/404/NotFound"));
const Login = lazy(() => import("./authentication/Login/Login"));
const Signup = lazy(() => import("./authentication/Signup/Signup"));
const Register = lazy(() => import("./authentication/Register/Register"));
const ProfileSetup = lazy(() => import("./authentication/ProfileSetup/ProfileSetup"));
const UserprofileSetup = lazy(() => import("./authentication/UserprofileSetup/UserprofileSetup"));
const Forgot = lazy(() => import("./authentication/Forgot/Forgot"));
const Reset = lazy(() => import("./authentication/Reset/Reset"));
const Map = lazy(() => import("./Pavement/components/Map/Map"));
const ProjectList = lazy(() => import("./Pavement/components/ProductList/ProductList"));
const MultiMap = lazy(() => import("./Pavement/components/MultiMap/MultiMap"));
const Survey = lazy(() => import("./Pavement/components/Survey/Survey"));
const NewPassword = lazy(() => import("./authentication/NewPassword/NewPassword"));
const ManageUser = lazy(() => import("./Pavement/components/ManageUser/ManageUser"));
const Profile = lazy(() => import("./utils/Profile/Profile"));
const EditEmail = lazy(() => import("./utils/Profile/EditEmail"));
const SafetyReports = lazy(() => import("./Pavement/components/reports/SafetyReports"));
const Video = lazy(() => import("./Pavement/components/Video/Video"));
const App = lazy(() => import("./Inventory/components/App/App"));
const Inventory_Report = lazy(() => import("./Inventory/components/Report/Report"));
export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={<Guard>{<Component />}</Guard>}
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: NotFound,
  },
  {
    exact: true,
    path: "/home",
    component: Home,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: "/resetPassword",
    component: NewPassword,
  },
  {
    exact: true,
    path: "/login",
    component: Login,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/signup",
    component: Signup,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/register",
    component: Register,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/activateprofile",
    component: ProfileSetup,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/userprofileSetup",
    component: UserprofileSetup,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/forgot",
    component: Forgot,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/reset",
    component: Reset,
    guard: GuestGuard,
  },
  {
    path: "/pavement/app",
    component: ProjectList,
    guard: AuthGuard,
  },
  {
    path: "/pavement/map/:id",
    component: Map,
    guard: AuthGuard,
  },
  {
    path: "/pavement/multiMap",
    component: MultiMap,
    guard: AuthGuard,
  },
  {
    path: "/pavement/report",
    component: Report,
    guard: AuthGuard, // Doesn't route to report [TODO: Needs debugging with auth guard]
  },
  {
    path: "/pavement/safetyReports",
    component: SafetyReports,
    guard: AuthGuard,
  },
  {
    path: "/pavement/finalReport",
    component: FinalReport,
    guard: AuthGuard,
  },
  {
    path: "/pavement/notifications",
    component: Notifications,
    guard: AuthGuard,
  },
  {
    path: "/pavement/versionControl",
    component: VersionControl,
    guard: AuthGuard,
  },
  {
    path: "/pavement/calculator",
    component: Calculator,
    guard: AuthGuard,
  },
  {
    path: "/pavement/traffic",
    component: Traffic,
    guard: AuthGuard,
  },
  {
    path: "/pavement/video",
    component: Video,
    guard: AuthGuard,
  },
  // {
  //   path: "/survey",
  //   component: Survey,
  //   guard: AdminGuard,
  // },
  {
    path: "/pavement/viewTask",
    component: ViewTask,
    guard: AdminGuard,
  },
  {
    path: "/pavement/myTask",
    component: MyTask,
    guard: AuthGuard,
  },
  {
    path: "/pavement/manageUser",
    component: ManageUser,
    guard: AdminGuard,
  },
  {
    path: "/profile",
    component: Profile,
    guard: AuthGuard,
  },
  {
    path: "/edit-email",
    component: EditEmail,
    guard: AuthGuard,
  },
  {
    path: "/reset",
    component: Forgot,
    guard: GuestGuard,
  },
  {
    path: "*",
    component: Login,
    guard: AuthGuard,
  },
  {
    path: "/test",
    component: SideBar,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: "/Inventory/app",
    component: App,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: "/Inventory/report",
    component: Inventory_Report,
    guard: AuthGuard,
  }
];

export default routes;
