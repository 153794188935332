import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import theLogo from "../../../assets/Roadvision.png";
//import theLogo from "../../../assets/logoup.png"
import { Link } from "react-router-dom";
import MainLoader from "../../../utils/MainLoader/MainLoader";
import AuthContext from "../../../contexts/AuthContext";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { MdRemoveRedEye } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { MdAdminPanelSettings, MdPermContactCalendar } from "react-icons/md";
import mapicon from "../../../assets/mapicon.svg"
import { GoPlus } from "react-icons/go";
import { Table, Space, Input, Checkbox, Button, Divider, Tag, Modal, List, Card, Typography, Flex, Select, DatePicker } from "antd";
import axios from "axios";
import { CheckCircleTwoTone } from '@ant-design/icons';
import Notification from '../../../utils/Notification';
import ProfileMenu from '../../../utils/ProfileMenu';
import CreateAppTask from './CreateAppTask';


const ViewTask = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [taskDetails, setTaskDetails] = useState(null);
    const [selectedTaskType, setSelectedTaskType] = useState(undefined);
    const [sortCriterion, setSortCriterion] = useState(undefined);
    const navigate = useNavigate();
    const location = useLocation();
    const { role } = useContext(AuthContext);
    const { Paragraph } = Typography;
    const { TextArea } = Input;
    const { Option } = Select;
    const [newDueDate, setNewDueDate] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const handleRefreshData = () => {
        setRefreshData(prevState => !prevState);
    };
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        navigate(location.pathname);
    };
    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}task/list_tasks`);
                setTasks(response.data.tasks);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchTasks();
    }, [refreshData]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const taskID = queryParams.get('taskID');

        if (taskID) {
            const task = tasks.find((task) => task.taskid === taskID);
            if (task) {
                setTaskDetails(task);
                setIsModalOpen(true);
            }
        }
    }, [location.search, tasks]);

    const handleViewDetails = (task) => {
        setTaskDetails(task);
        setIsModalOpen(true);
        const taskURL = `${location.pathname}?taskID=${task.taskid}`;
        navigate(taskURL);
    };

    const { Search } = Input;
    const [checkboxState, setCheckboxState] = useState({
        allTasks: true,
        pending: true,
        inProgress: true,
        completed: true,
        overdue: true,
        closed: true,
    });
    const [indeterminate, setIndeterminate] = useState(false);
    const handleCheckboxChange = (checkbox) => {
        if (checkbox === 'allTasks') {
            const newState = !checkboxState.allTasks;
            setCheckboxState({
                allTasks: newState,
                pending: newState,
                inProgress: newState,
                completed: newState,
                overdue: newState,
                closed: newState,
            });
            setIndeterminate(false);
        } else {
            setCheckboxState((prevState) => ({
                ...prevState,
                [checkbox]: !prevState[checkbox],
            }));
            const newState = {
                ...checkboxState,
                [checkbox]: !checkboxState[checkbox],
            };
            const { allTasks, ...rest } = newState;
            const allChecked = Object.values(rest).every((value) => value);
            const anyChecked = Object.values(rest).some((value) => value);

            setIndeterminate(!allChecked && anyChecked);
            setCheckboxState((prevState) => ({
                ...prevState,
                allTasks: allChecked,
            }));
        }
    };
    const [filteredTasks, setFilteredTasks] = useState([]);
    useEffect(() => {
        const filterTasks = () => {
            let filtered = [...tasks];

            // Filter by search text
            if (searchText) {
                filtered = filtered.filter(task =>
                    task.taskTitle.toLowerCase().includes(searchText.toLowerCase())
                );
            }

            // Filter by status
            if (!checkboxState.allTasks) {
                const selectedStatuses = Object.entries(checkboxState)
                    .filter(([key, value]) => value && key !== 'allTasks')
                    .map(([key]) => {
                        switch (key) {
                            case 'pending': return 'Pending';
                            case 'inProgress': return 'In Progress';
                            case 'completed': return 'Completed';
                            case 'overdue': return 'Overdue';
                            case 'closed': return 'Closed';
                            default: return '';
                        }
                    });
                filtered = filtered.filter(task => selectedStatuses.includes(task.status));
            }

            // Filter by task type
            if (selectedTaskType) {
                filtered = filtered.filter(task => task.taskType.toLowerCase() === selectedTaskType);
            }

            // Sort the filtered tasks
            filtered.sort((a, b) => {
                switch (sortCriterion) {
                    case 'dueDate':
                        return new Date(b.dueDate) - new Date(a.dueDate);
                    case 'createdDate':
                        return new Date(b.created_at) - new Date(a.created_at);
                    case 'assignedTo':
                        return a.assignedTo.localeCompare(b.assignedTo);
                    default:
                        return 0;
                }
            });

            setFilteredTasks(filtered);
        };

        filterTasks();
    }, [tasks, checkboxState, selectedTaskType, sortCriterion, searchText]);
    const handleTaskTypeChange = (value) => {
        setSelectedTaskType(value);
    };
    const resetFilters = () => {
        setSelectedTaskType(undefined);
        setSortCriterion(undefined);
    };
    const handleSearch = (value) => {
        setSearchText(value);
    };
    const disabledDate = current => {
        return current && current < moment().startOf('day');
    };

    const CustomTableHeader = ({ checkboxState, handleCheckboxChange }) => (
        <>
            <div style={{ width: '100%' }}>
                <Search
                    placeholder="Search for a task"
                    allowClear
                    enterButton={<Button type="primary" ghost>Search</Button>}
                    size="large"
                    onSearch={handleSearch}
                    style={{ width: '100%' }}
                />
            </div>
            <Divider style={{ margin: '5px 0px' }} />
            <Space>
                <Select
                    value={sortCriterion}
                    style={{ width: 200, borderColor: '#1890ff' }}
                    onChange={(value) => setSortCriterion(value)}
                    placeholder="Sort by"
                >
                    <Option value="dueDate">Due Date</Option>
                    <Option value="createdDate">Created Date</Option>
                    <Option value="assignedTo">Assigned To</Option>
                </Select>

                <Select
                    value={selectedTaskType}
                    placeholder="Filter by task type"
                    style={{ width: 250, borderColor: '#1890ff' }}
                    onChange={handleTaskTypeChange}
                >
                    <Option value="maintenance">Maintenance</Option>
                    <Option value="survey">Survey</Option>
                </Select>
                <Button type="primary" ghost onClick={resetFilters}>Clear filter</Button>
                <Checkbox
                    indeterminate={indeterminate}
                    checked={checkboxState.allTasks}
                    onChange={() => handleCheckboxChange('allTasks')}
                >
                    All Task
                </Checkbox>
                <Checkbox
                    checked={checkboxState.pending}
                    onChange={() => handleCheckboxChange('pending')}
                >
                    Pending
                </Checkbox>
                <Checkbox
                    checked={checkboxState.inProgress}
                    onChange={() => handleCheckboxChange('inProgress')}
                >
                    In Progress
                </Checkbox>
                <Checkbox
                    checked={checkboxState.completed}
                    onChange={() => handleCheckboxChange('completed')}
                >
                    Completed
                </Checkbox>
                <Checkbox
                    checked={checkboxState.overdue}
                    onChange={() => handleCheckboxChange('overdue')}
                >
                    Overdue
                </Checkbox>
                <Checkbox
                    checked={checkboxState.closed}
                    onChange={() => handleCheckboxChange('closed')}
                >
                    Closed
                </Checkbox>
            </Space>
        </>
    );

    const columns = [
        {
            title: 'Task Title',
            dataIndex: 'taskTitle',
            key: 'taskTitle',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Task Type',
            dataIndex: 'taskType',
            key: 'taskType',
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
        },
        {
            title: 'Created On',
            dataIndex: 'created_at',
            key: 'createdOn',
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (dueDate) => {
                const date = new Date(dueDate);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (status) => {
                switch (status) {
                    case 'Closed':
                        return <Tag color="#ECECEC" style={{ color: '#425466' }}>Closed</Tag>;
                    case 'Pending':
                        return <Tag color="#FFFBD2" style={{ color: '#B35A04' }}>Pending</Tag>;
                    case 'In Progress':
                        return <Tag color="#EBF3FF" style={{ color: '#1967D2' }}>In Progress</Tag>;
                    case 'Completed':
                        return <Tag color="#DEFFE7" style={{ color: '#34A853' }}>Completed</Tag>;
                    case 'Overdue':
                        return <Tag color="#FFE3E3" style={{ color: '#DA2727' }}>Overdue</Tag>;
                    default:
                        return null;
                }
            },

        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    {record.status === 'Closed' ? (
                        <MdRemoveRedEye onClick={() => handleViewDetails(record)} style={{ cursor: 'pointer' }} />
                    ) : (
                        <AiFillEdit onClick={() => handleViewDetails(record)} style={{ cursor: 'pointer' }} />
                    )}
                </Space>
            ),
        },
    ];

    const statusBodyTemplate = (taskDetails) => {
        const getStatusTag = (status) => {
            switch (status) {
                case 'Closed':
                    return <Tag color="#ECECEC" style={{ color: '#425466' }}>Closed</Tag>;
                case 'Pending':
                    return <Tag color="#FFFBD2" style={{ color: '#B35A04' }}>Pending</Tag>;
                case 'In Progress':
                    return <Tag color="#EBF3FF" style={{ color: '#1967D2' }}>In Progress</Tag>;
                case 'Completed':
                    return <Tag color="#DEFFE7" style={{ color: '#34A853' }}>Completed</Tag>;
                case 'Overdue':
                    return <Tag color="#FFE3E3" style={{ color: '#DA2727' }}>Overdue</Tag>;
                default:
                    return null;
            }
        };

        return taskDetails.status ? getStatusTag(taskDetails.status) : null;
    };

    const renderContent = () => {
        if (taskDetails) {
            return (
                <Card
                    title={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>
                                    Assigned Task Details
                                </span>
                                <span style={{ fontSize: 14, fontWeight: 400 }}>
                                    Type: {taskDetails.taskType} Task
                                </span>
                            </div>

                            <div className="flex items-center" style={{ position: 'relative', width: 250, height: 45 }}>
                                <MdAdminPanelSettings style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', zIndex: 10 }} />
                                <Input
                                    disabled={true}
                                    value={`Assigned by ${taskDetails.assignedBy}`}
                                    style={{ paddingLeft: 35, width: '100%', height: '100%', borderRadius: 20, fontWeight: 600 }}
                                />
                            </div>
                        </div>
                    }
                    bordered={false}
                    style={{ width: '100%' }}
                >
                    <Paragraph style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFFBFF', padding: '10px', margin: '0 -24px', textAlign: 'center' }}>
                        <img src={mapicon} alt="map" />{taskDetails.roadNames}
                    </Paragraph>
                    <Flex gap="small">
                        <Flex gap="small" vertical>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 100,
                                    width: 320,
                                    backgroundColor: '#fff',
                                    overflow: 'hidden',
                                    margin: '16px 0px',
                                    padding: '16px',
                                    border: '1px solid #fff',
                                    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div style={{ fontSize: '16px', fontWeight: '600', marginBottom: '8px' }}>Assigned user for this task</div>
                                <div
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '58px',
                                    }}
                                >
                                    <List size="small">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px',
                                                padding: '10px',
                                                backgroundColor: 'transparent',
                                            }}
                                        >
                                            <MdPermContactCalendar />{taskDetails.assignedTo}
                                        </div>
                                    </List>
                                </div>
                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 190,
                                    width: 320,
                                    backgroundColor: '#fff',
                                    overflow: 'hidden',
                                    padding: '16px',
                                    border: '1px solid #fff',
                                    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <List size="small"
                                    header={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: 16, fontWeight: 600 }}>Task status</span>
                                        <span>{statusBodyTemplate(taskDetails)}</span>
                                    </div>
                                    }
                                >
                                    <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: 16, fontWeight: 600 }}> Created on </span>
                                        <span>{taskDetails.created_at}</span>
                                    </div>
                                    {taskDetails.accepted_at && (
                                        <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}> Accepted on </span>
                                            <span>{taskDetails.accepted_at}</span>
                                        </div>
                                    )}
                                    {taskDetails.completed_at && (
                                        <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}> Completed on </span>
                                            <span>{taskDetails.completed_at}</span>
                                        </div>
                                    )}
                                    {taskDetails.closed_at && (
                                        <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}> Closed on </span>
                                            <span>{taskDetails.closed_at}</span>
                                        </div>
                                    )}
                                </List>
                            </div>
                        </Flex>
                        <div
                            id="taskdiv"
                            style={{
                                height: 313,
                                flexGrow: 1,
                                overflow: 'hidden',
                                margin: '16px 0px',
                                padding: '0px 16px',
                                border: '1px solid #fff',
                                boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
                                overflowY: 'auto',
                            }}
                        >
                            <Flex gap="small" vertical>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: 16, fontWeight: 600 }}>Task Title</span>
                                    <span >{taskDetails.taskTitle}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                    <span style={{ fontSize: 16, fontWeight: 600 }}>Task Description</span>
                                    <span>{taskDetails.taskDescription}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
                                    <span style={{ marginRight: '16px', fontSize: 16, fontWeight: 600 }}>Due Date</span>
                                    <span style={{ fontSize: 16, fontWeight: 400 }}>{formatDate(taskDetails.dueDate)}</span>
                                    {taskDetails.status === 'Overdue' && (
                                        <DatePicker
                                            id="date"
                                            value={newDueDate}
                                            onChange={(date, dateString) => setNewDueDate(date)}
                                            style={{ marginLeft: 16 }}
                                            disabledDate={disabledDate}
                                        />
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
                                    <span style={{ marginRight: '32px', fontSize: 16, fontWeight: 600 }}>Task ID</span>
                                    <span style={{ fontSize: 16, fontWeight: 400 }}>{taskDetails.taskid}</span>
                                </div>
                                {taskDetails.feedback && (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2, marginBottom: 4 }}>
                                        <span style={{ fontSize: 16, fontWeight: 600 }}>Feedback from the assigned user</span>
                                        <TextArea
                                            disabled={true}
                                            placeholder="Feedback from the assigned user"
                                            autoSize={{ minRows: 3, maxRows: 4 }}
                                            style={{ width: '100%', height: '100%', fontWeight: 600, backgroundColor: '#EDEDED' }}
                                            value={taskDetails.feedback}
                                        />
                                    </div>
                                )}
                            </Flex>
                        </div>
                    </Flex>
                </Card >
            );
        }
        return null;
    }
    const footerContent = () => {
        const handleReset = async () => {
            try {
                await axios.post(
                    `${process.env.REACT_APP_BASE_URL}task/reset_due_date`,
                    { taskId: taskDetails.taskid, newDueDate: newDueDate },
                    { headers: { 'Content-Type': 'application/json' } }
                )
                handleRefreshData();
                setIsModalOpen(false);
            } catch (error) {
                console.error(error);
            }
        };
        const handleClosed = async () => {
            try {
                await axios.post(
                    `${process.env.REACT_APP_BASE_URL}task/close_task`,
                    { taskId: taskDetails.taskid },
                    { headers: { 'Content-Type': 'application/json' } }
                )
                handleRefreshData();
                setIsModalOpen(false);
            } catch (error) {
                console.error(error);
            }
        };
        if (taskDetails) {
            if (taskDetails.status === 'Closed') {
                return [<Button key="close" danger ghost onClick={handleCancel}>Close this window</Button>];
            } else if (taskDetails.status === 'Overdue') {
                return [
                    <div key="button-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button key="exit" danger ghost onClick={handleCancel}>Exit</Button>
                        <Button key="reset" type="primary" icon={<AvTimerIcon />} style={{ background: '#1967D2' }} onClick={handleReset}>Reset Due Date</Button>
                    </div>
                ];
            } else {
                return [
                    <div key="button-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button key="exit" danger ghost onClick={handleCancel}>Exit</Button>
                        <Button key="assign" type="primary" icon={<CheckCircleTwoTone />} style={{ background: '#1967D2' }} onClick={handleClosed}>Closed Task</Button>
                    </div>
                ];
            }
        }
    };
    return (
        <>
            <div className="flex">
                <div className="flex w-full justify-between px-8 items-center h-16 shadow-md fixed top-0 z-40  bg-white">
                    <div className="logo">
                        <Link to="/pavement/app" className="logo">
                            <img src={theLogo} alt="logo" className="w-44" />
                        </Link>
                    </div>
                    <div className="right_profile flex flex-row gap-12">
                        <div className="flex items-center">
                            <Notification />
                        </div>
                        <ProfileMenu />
                    </div>
                </div>
                <div className="header mt-6 px-8 flex w-full justify-between items-center fixed top-[68px] p-2 z-40 left-0 bg-white">
                    <div>
                        <h1 className="text-[1.5rem] font-semibold text-[#0A2540]">
                            Task Management
                        </h1>
                        <p className="text-[#5F6368]">
                            Manage user maintenance and user survey request tasks
                        </p>
                    </div>
                    <div className="flex gap-3">
                        {role === 'Admin' && (
                            <button
                                type="button"
                                className="text-white bg-[#1967D2] font-medium rounded-md text-sm px-5 py-2.5 flex gap-2 items-center place-items-center"
                                onClick={() => setDialogVisible(true)}
                            >
                                <GoPlus size={18} /> Add User Survey Task
                            </button>
                        )}
                    </div>

                </div>
            </div>
            <div className="table-container mt-36 p-5 text-lg bg-white">
                {isLoading ? <MainLoader /> :
                    <Table
                        columns={columns}
                        dataSource={filteredTasks}
                        bordered
                        title={() => <CustomTableHeader setSearchText={setSearchText} checkboxState={checkboxState} handleCheckboxChange={handleCheckboxChange} />}
                        scroll={{
                            y: 240,
                        }}
                    />
                }
            </div>
            <Modal
                centered
                closable={false}
                open={isModalOpen}
                onCancel={handleCancel}
                width="70vw"
                footer={footerContent()}
            >
                {renderContent()}
            </Modal>

            <CreateAppTask
                open={dialogVisible}
                onHide={() => setDialogVisible(false)}
                onTaskAssigned={handleRefreshData}
            />
        </>
    )
}

export default ViewTask