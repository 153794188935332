import React, { useState } from "react";
import { MdOutlineDashboard } from "react-icons/md";
import { FaCalculator } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import { GiBackwardTime } from "react-icons/gi";
import { Link } from "react-router-dom";
import MultiMap from "../MultiMap/MultiMap";
import { useSearchParams } from "react-router-dom";

function SideBar() {
  const menus = [
    { name: "Map", link: "#", icon: MdOutlineDashboard },
    {
      name: "Report",
      //link: "http://localhost:3000/report",
      icon: FaCalculator,
    },
    { name: "Notifications", link: "#", icon: IoNotifications },
    { name: "VersionControl", link: "#", icon: GiBackwardTime },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const uid_list = JSON.parse(
    decodeURIComponent(searchParams.get("listOfIds"))
  );

  return (
    <>
      <section className="flex content-between">
        <div
          className="bg-[#F5FAFF] min-h-screen w-16
           text-[#425466] px-4"
        >
          <div className="mt-4 flex flex-col gap-4 relative">
            {menus?.map((menu, i) => {
              console.log(menu);
              return (
                <Link
                  to={menu.link}
                  key={i}
                  className={` ${
                    menu?.margin && "mt-5"
                  } group flex items-center text-sm  gap-3 font-medium p-2 hover:bg-slate-200 rounded-md`}
                >
                  <div>{React.createElement(menu?.icon, { size: "21" })}</div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="m-3 text-xl text-gray-900 font-semibold">
          <MultiMap uid_list={uid_list} />
        </div>
      </section>
    </>
  );
}

export default SideBar;
