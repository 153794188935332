import React, { useState, useEffect } from 'react';
import { Badge, Popover, List, Button } from 'antd';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { CheckCircleOutlined, FileOutlined, SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';

const notificationTypes = {
    "Platform greet": <InfoCircleOutlined />,
    "Org greet": <InfoCircleOutlined />,
    "Task assigned": <FileOutlined />,
    "Task overdue approaching": <FileOutlined />,
    "Task overdue": <FileOutlined />,
    "Task accepted": <CheckCircleOutlined />,
    "Task completed": <CheckCircleOutlined />,
    "Task due date reset": <SearchOutlined />,
    "Activate user": <CheckCircleOutlined />,
    "Deactivate user": <CheckCircleOutlined />,
    "Permission updated": <CheckCircleOutlined />,
    "Permission downgrade": <CheckCircleOutlined />,
    "Password reset": <SearchOutlined />,
    "Info": <InfoCircleOutlined />
};

const fetchNotifications = async (setNotifications) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}notification/get_notification`);
        if (response.status === 200) {
            if (response.data.notifications) {
                let notificationsArray = Array.isArray(response.data.notifications)
                    ? response.data.notifications.map(item => JSON.parse(item))
                    : [JSON.parse(response.data.notifications)];
                notificationsArray = notificationsArray.map(notification => {
                    if (notification.created_at && notification.created_at.$date) {
                        notification.created_at = new Date(notification.created_at.$date);
                    }
                    return notification;
                });
                setNotifications(notificationsArray);
            } else {
                setNotifications([]);
            }
        } else {
            console.error('Failed to fetch notifications');
            setNotifications([]);
        }
    } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]);
    }
};


const markNotificationAsRead = async (notificationId, onUpdateBadgeCounter) => {
    try {
        await axios.patch(`${process.env.REACT_APP_BASE_URL}notification/notifications/${notificationId}/read`);
        onUpdateBadgeCounter();
    } catch (error) {
        console.error('Error marking notification as read:', error);
    }
};

const NotificationContent = ({ notifications, onUpdateBadgeCounter }) => (
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        avatar={getNotificationIcon(item.notificationtype)}
                        title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                            <a href="#" onClick={() => markNotificationAsRead(item.notificationId, onUpdateBadgeCounter)}>
                                {item.message}
                            </a>
                            <span style={{ fontSize: 11, color: '#000000a8' }}>{formatDistanceToNow(new Date(item.created_at))} ago</span>
                            <div style={{ display: 'flex', marginTop: '8px', gap: '8px' }}>
                                {item.button_text && item.link &&
                                    <Button
                                        type="link"
                                        href={item.link}
                                        style={{ backgroundColor: '#F1F7FF', color: '#1967D2', marginTop: '8px' }}
                                    >
                                        {item.button_text}
                                    </Button>
                                }
                                <Button
                                    type="link"
                                    style={{ backgroundColor: '#F1F7FF', color: '#1967D2', marginTop: '8px' }}
                                    onClick={() => markNotificationAsRead(item.notificationId, onUpdateBadgeCounter)}
                                >
                                    Mark as Read
                                </Button>
                            </div>
                        </div>}
                    />
                </List.Item>
            )}
        />
    </div>
);

const getNotificationIcon = (type) => {
    return notificationTypes[type] || notificationTypes["Info"];
};

const Notification = () => {
    const [notifications, setNotifications] = useState([]);
    const [openPopover, setOpenPopover] = useState(false);

    useEffect(() => {
        fetchNotifications(setNotifications);
    }, []);

    const updateBadgeCounter = () => {
        fetchNotifications(setNotifications);
    };

    const togglePopover = () => {
        setOpenPopover(!openPopover);
    };

    const handleMarkAllAsRead = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`${process.env.REACT_APP_BASE_URL}notification/notifications/read_all`);
            updateBadgeCounter();
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    }

    return (
        <div>
            <Popover
                placement="bottomRight"
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Notifications</div>
                        <Button type="link" style={{ color: '#1677ff' }} onClick={handleMarkAllAsRead}>Mark All as Read</Button>
                    </div>
                }
                content={<NotificationContent notifications={notifications} onUpdateBadgeCounter={updateBadgeCounter} />}
                trigger="click"
                open={openPopover}
                onOpenChange={togglePopover}
                overlayStyle={{ minWidth: '450px' }}
            >
                <Badge count={notifications.length} overflowCount={99}>
                    <CircleNotificationsIcon className="text-[#425466] cursor-pointer" />
                </Badge>
            </Popover>
        </div>
    );
};

export default Notification;
